import React, { useState, useEffect } from "react";
import { images } from "../constants/images";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import Button from "../components/Button";

// sections
import { WelcomeSection } from "../components/Sections";
import { FeaturesSection } from "../components/Sections";

interface Props {}

const Conclave: React.FC<Props> = () => {
  const [allowAnimations, setAllowAnimations] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAllowAnimations(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  //=========================================
  // Setup useInView for each section
  const animationVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const [animation1Ref, animation1InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation2Ref, animation2InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation3Ref, animation3InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation4Ref, animation4InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation5Ref, animation5InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });

  return (
    <motion.div
      className="motion-div"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.25 } }}
    >
      <section id="Conclave--page">
        <section className="COMING-SOON">
          <div className="content--container">
            <h3>COMING SOON</h3>
            <p>
              The Conclave is currently under construction. Please check back
              soon for updates.
            </p>
          </div>
        </section>

        <WelcomeSection
          ref={animation1Ref}
          initial="hidden"
          animate={animation1InView ? "visible" : "hidden"}
          variants={animationVariants}
          title="Welcome to The Conclave"
          subTitle="Where like-minded individuals collaborate"
          textParagraph="  At the heart of the Awakened Generation Network lies The
          Conclave — a dynamic and exclusive segment of The Awakened
          Generation Network, dedicated to individuals passionate
          about wealth creation, personal growth, and entrepreneurial
          spirit."
          textParagraph2="It serves as a nexus for ambitious minds seeking not only
          financial success but also personal development and
          meaningful connections."
        />

        <FeaturesSection
          ref={animation5Ref}
          initial="hidden"
          animate={animation5InView ? "visible" : "hidden"}
          variants={animationVariants}
          features={["Networking", "Mentorship", "Workshops", "Resources"]}
        />

        <section className="INTRO--section">
          <div className="content--wrapper">
            <motion.div
              ref={animation2Ref}
              initial="hidden"
              animate={animation2InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="content--container">
                <figure className="image">
                  <img src={images.conclaveIMG_3} />
                </figure>

                <div className="text">
                  <h2>Unveiling The Conclave</h2>

                  <p>
                    The Conclave is a dynamic and exclusive segment of The
                    Awakened Generation Network, dedicated to individuals
                    passionate about wealth creation, personal growth, and
                    entrepreneurial spirit. It serves as a nexus for ambitious
                    minds seeking not only financial success but also personal
                    development and meaningful connections.
                  </p>

                  <p>
                    The mission of The Conclave is to empower members with the
                    knowledge, resources, and networks necessary to achieve
                    financial independence and personal growth. Our vision is to
                    cultivate a community where collaboration, innovation, and
                    shared wisdom lead to collective and individual prosperity.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="PERKS--section">
          <div className="content--wrapper">
            <motion.div
              ref={animation3Ref}
              initial="hidden"
              animate={animation3InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="content--container">
                <div className="text">
                  <h2>Core Pillars of The Conclave</h2>

                  <div className="perk">
                    <h3>Personal Development Pathways</h3>
                    <p>
                      Focus on soft skills, emotional intelligence, and
                      achieving a balanced life.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>A Library at Your Fingertips</h3>

                    <p>
                      Utilize tools and templates essential for your financial
                      and business journey.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Secure and Confidential</h3>
                    <p>
                      Benefit from private, encrypted communication channels for
                      your peace of mind.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Mentorship and Growth</h3>
                    <p>
                      Connect with mentors who can guide you on your path to
                      success.
                    </p>
                  </div>
                </div>

                <figure className="image">
                  <img src={images.conclaveIMG_4} />
                </figure>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="JOIN--section">
          <div className="content--wrapper">
            <div className="top-bar" />

            <div className="content--container">
              <motion.div
                ref={animation4Ref}
                initial="hidden"
                animate={animation4InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="text">
                  <h2>Join Us Today</h2>

                  <p>
                    Embrace the power of collective wisdom. Whether you're a
                    seasoned professional or an eager learner, The Conclave
                    invites you to be part of a thriving ecosystem, where
                    together, we propel each other toward excellence.
                  </p>

                  <Button
                    text="Join the Discord"
                    to="https://discord.gg/stuZTEga"
                    style={{
                      marginTop: "2rem",
                      // maxWidth: "200px",
                    }}
                  />
                </div>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section>
      </section>
    </motion.div>
  );
};

export default Conclave;

{
  /* <section className="WELCOME--section">
          <div className="content--wrapper">
            <div className="top-bar" />

            <div className="content--container">
              <motion.div
                ref={animation1Ref}
                initial="hidden"
                animate={animation1InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="text">
                  <div className="titles--container">
                    <h2>Welcome to The Conclave</h2>
                    <h3>Where like-minded individuals collaborate </h3>
                  </div>

                  <p>
                    At the heart of the Awakened Generation Network lies The
                    Conclave — a dynamic and exclusive segment of The Awakened
                    Generation Network, dedicated to individuals passionate
                    about wealth creation, personal growth, and entrepreneurial
                    spirit.
                  </p>

                  <p>
                    It serves as a nexus for ambitious minds seeking not only
                    financial success but also personal development and
                    meaningful connections.
                  </p>
                </div>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section> */
}

{
  /* <section className="FEATURES--section">
          <div className="content--wrapper">
            <div className="content--container">
              <motion.div
                ref={animation5Ref}
                initial="hidden"
                animate={animation5InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="feature--list">
                  <div className="feature">
                    <h3>Networking</h3>
                  </div>

                  <div className="feature">
                    <h3>Mentorship</h3>
                  </div>

                  <div className="feature">
                    <h3>Workshops</h3>
                  </div>

                  <div className="feature">
                    <h3>Resources</h3>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </section> */
}
