import React from "react";

interface Props {
  // Define the props for your component here
}

const Background: React.FC<Props> = () => {
  // Implement your component logic here

  return (
    <div id="Background--layout">
      <div className="left-line" />
      <div className="center-line" />
      <div className="right-line" />
      <div className="container 1" />
      <div className="container 2" />
      <div className="container 3" />
      <div className="container 4" />
      <div className="container 5" />
    </div>
  );
};

export default Background;
