import React, { ChangeEvent, CSSProperties } from "react";

interface InputProps {
  type: string;
  value: string;
  onChange: (value: string) => void;
  style?: CSSProperties;
  label: string;
  placeholder: string;
}

const Input: React.FC<InputProps> = ({
  type,
  value,
  onChange,
  style,
  placeholder,
  label,
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <div id="Input--component">
      <label>{label}</label>
      <input
        type={type}
        value={value}
        onChange={handleInputChange}
        style={style}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Input;
