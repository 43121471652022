import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { images } from "../../constants/images";

export interface FAQData {
  question: string;
  answer: string;
}

export interface DataState {
  faq: FAQData[];
}

const initialState: DataState = {
  faq: [
    {
      question: "What is The Awakened Generation Network?",
      answer:
        "The Awakened Generation Network is a comprehensive community focused on personal and professional growth, encompassing various branches like The Discord, The Conclave, The Mastermind, The Consortium, The Facilities, and The Sanctum. Each branch is dedicated to specific aspects of development and enlightenment.",
    },
    {
      question: "How can I become a member of The Awakened Generation Network?",
      answer:
        "Membership is obtained through an application process via our website. Each branch has its unique application process and criteria, tailored to align members with the spaces that best suit their interests and goals.",
    },
    {
      question: "Are there different levels of membership?",
      answer:
        "Yes, the network offers different membership tiers, from basic to premium and VIP levels. Each tier provides varying degrees of access to our content, events, and community spaces.",
    },
    {
      question: "Can I be a member of multiple branches within the network?",
      answer:
        "Absolutely! Members are encouraged to explore and join multiple branches that align with their personal and professional growth objectives.",
    },
    {
      question:
        "What kind of events and workshops does The Awakened Generation Network offer?",
      answer:
        "The network offers a diverse range of events and workshops, including spiritual retreats, financial workshops, entrepreneurial masterclasses, personal development programs, and more. These are tailored to the specific focus of each branch.",
    },
    {
      question:
        "How does The Awakened Generation Network ensure the privacy and security of its members?",
      answer:
        "Member privacy and security are prioritized through strict data protection policies, compliance with global data protection laws, and the use of secure, encrypted communication channels for private groups.",
    },
    {
      question:
        "Can I access The Facilities if I am a member of any branch of the network?",
      answer:
        "Yes, all members of The Awakened Generation Network have access to The Facilities, which offer a variety of workshops, events, and wellness programs.",
    },
    {
      question: "What resources are available for members?",
      answer:
        "Members have access to a wealth of resources, including expert articles, e-books, webinar recordings, and exclusive content tailored to the focus of each branch.",
    },
    {
      question:
        "Is there an option for online participation for those who cannot attend in-person events?",
      answer:
        "Definitely. The network offers numerous online workshops, webinars, and virtual community gatherings to ensure accessibility for all members, regardless of location.",
    },
    {
      question: "How can I contribute to or volunteer within the network?",
      answer:
        "Member contributions and volunteering are welcomed. Opportunities include leading workshops, contributing content, participating in community service projects, or assisting in organizing network events.",
    },
    {
      question: "Is the Network free to join?",
      answer:
        "It depends on which component you wish to join. The Conclave is free to join for everyone, offering an open and inclusive entry point to the network's ecosystem.",
    },
    {
      question: "What will I gain from joining the Network?",
      answer:
        "By joining the network, you gain access to a powerful and interconnected ecosystem that offers diverse opportunities for growth, collaboration, and personal development.",
    },
    {
      question: "What is the Conclave?",
      answer:
        "The Conclave serves as a free-to-join Discord server within the network, providing members with access to a plethora of free resources, a vibrant and supportive community, and opportunities for engagement and exchange of ideas.",
    },
    {
      question: "How do I join the Conclave?",
      answer:
        "Joining the Conclave is simple! You can gain entry by following the provided invitation link or by requesting an invite through the network's designated channels.",
    },
    {
      question: "What is the Mastermind?",
      answer:
        "An exclusive group filled with selectivley chosen individuals based upon their contributions to the network and their knowledge and skills.",
    },
    {
      question: "How do I join the Mastermind?",
      answer:
        "You can join the Mastermind either by actively contributing and demonstrating value within the Conclave or by opting for a membership requiring a monthly fee of 30 euros.",
    },
    {
      question: "What will I gain from joining the Mastermind?",
      answer:
        "Joining the Mastermind provides access to advanced resources, exclusive insights, and heightened opportunities for collaboration, allowing members to delve deeper into specialized knowledge areas and foster high-level connections.",
    },
    {
      question: "How do I join the Consortium?",
      answer:
        "To join the Consortium, submit an application through the designated application portal provided on the network's website or contact the network's administration for further guidance.",
    },
    {
      question: "How do I gain access to a Facility?",
      answer:
        "Gain access to a Facility by sending an appeal via email to @awakenedgeneration.network@gmail.com, expressing your interest and intent to utilize the resources and opportunities offered within the Facility.",
    },
    {
      question: "What will I gain from entering a Facility?",
      answer:
        "Entering a Facility offers the opportunity to reprogram your mindset, cultivate new habits, and engage with fellow awakened minds in real-world settings, fostering personal development and growth through immersive experiences.",
    },
    {
      question: "How do I gain access to the Sanctum?",
      answer:
        "Access to the Sanctum is granted exclusively through a certified invitation. Upon receiving a validated entry-validation-card, individuals can join and enter the Sanctum, ensuring a selective and curated environment for exploration and enlightenment. No valid card means no entry.",
    },
    {
      question: "What opportunities for networking exist within the Network?",
      answer:
        "The Network offers diverse networking opportunities across its branches. The Conclave provides a bustling community space, while the Mastermind fosters connections among high-achieving individuals. The Consortium offers collaborative partnerships, the Facilities and the Sanctum facilitates connections with like-minded seekers.",
    },
    {
      question: "How often are events or workshops held within the Network?",
      answer:
        "Events and workshops frequency vary across branches, but all aim to provide regular engagements. The Conclave hosts daily discussions and events, while the Mastermind schedules sessions periodically. The Consortium and Sanctum arrange events on a semi-regular basis, focusing on collaboration and deeper explorations.",
    },
    {
      question:
        "Are there any prerequisites for joining specific branches within the Network?",
      answer:
        "Yes, each branch may have its criteria for entry. The Conclave is open to all, while the Mastermind requires either active contribution or a membership fee. The Consortium typically requires an application process, the facilities require a validated application + reservation and the Sanctum is accessible by certified invitation only.",
    },
    {
      question:
        "Can I transition between different branches within the Network?",
      answer:
        "Transitioning between branches is possible based on eligibility criteria. For instance, active contributors to the Conclave may qualify for Mastermind access. Contact the administration for guidance on transitioning between branches.",
    },
    {
      question: "How are disputes or conflicts handled within the Network?",
      answer:
        "The Network upholds principles of fairness and inclusivity. Disputes or conflicts are addressed through designated channels or moderators within each branch to ensure a respectful and amicable resolution.",
    },
    {
      question:
        "What resources are available for personal development within the Network?",
      answer:
        "Each branch offers tailored resources. The Conclave provides access to free resources and discussions. The Mastermind provides advanced tools and exclusive insights. The Facilities offer comprehensive training, and the Sanctum focuses on introspective practices.",
    },
    {
      question:
        "Is there a trial period available for certain branches within the Network?",
      answer:
        "Yes, some branches offer trial or introductory periods. The Conclave, for instance, offers an open-entry trial, while specific arrangements might exist for limited trials in other branches. Contact administration for trial-related inquiries.",
    },
    // {
    //   question: "",
    //   answer: "",
    // },
  ],
};

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    addFAQ: (state, action: PayloadAction<FAQData>) => {
      state.faq.push(action.payload);
    },
    // updateFAQ: (state, action: PayloadAction<FAQData>) => {
    //   const { id } = action.payload;
    //   const index = state.faq.findIndex((faq) => faq.id === id);
    //   if (index !== -1) {
    //     state.faq[index] = action.payload;
    //   }
    // },
    // removeFAQ: (state, action: PayloadAction<number>) => {
    //   const id = action.payload;
    //   state.faq = state.faq.filter((faq) => faq.id !== id);
    // },
  },
});

export default faqSlice.reducer;
export const { addFAQ } = faqSlice.actions;
