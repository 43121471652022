import React, { useState } from "react";
import { motion } from "framer-motion";

import Input from "../components/Input";
import Textarea from "../components/Textarea";

interface FormData {
  firstname: string;
  lastname: string;
  age: number;
  email: string;
  alias?: string;
  country?: string;
  messageTitle: string;
  message: string;
}

const Contact: React.FC = () => {
  const [step, setStep] = useState<number>(1);
  // const [activeStep, setActiveStep] = useState<boolean>(1);
  const [formData, setFormData] = useState<FormData>({
    firstname: "",
    lastname: "",
    age: 0,
    email: "",
    alias: "",
    country: "",
    messageTitle: "",
    message: "",
  });
  const [attempted, setAttempted] = useState<boolean>(false);

  const [activeForm, setActiveForm] = useState<boolean>(false);

  // const handleInputChange = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleInputChange = (name: string) => (value: string) => {
  //   setFormData({ ...formData, [name]: value });
  // };

  // const handleInputChange = (name: string) => {
  //   return (value: string) => {
  //     setFormData({ ...formData, [name]: value });
  //   };
  // };

  const handleInputChange = (name: string) => {
    return (value: string) => {
      const updatedValue = name === "age" ? parseInt(value, 10) : value;
      setFormData({ ...formData, [name]: updatedValue });
    };
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    console.log("Form data submitted", formData);
  };

  // const handleInputChange = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const isValidStep1 = () =>
    formData.firstname !== "" && formData.lastname !== "" && formData.age > 0;
  const isValidStep2 = () => formData.email !== "";
  const isValidStep3 = () =>
    formData.messageTitle !== "" && formData.message !== "";

  const handleNextClick = (nextStep: number) => {
    if (
      (nextStep === 2 && isValidStep1()) ||
      (nextStep === 3 && isValidStep2()) ||
      nextStep === 4
    ) {
      setStep(nextStep);
      setAttempted(false); // Reset attempted state
    } else {
      setAttempted(true); // Set attempted state to true to show notification and change border color
    }
  };

  const showValidationError = () => {
    if (attempted) {
      return (
        <p style={{ color: "red" }}>Please fill in all required fields.</p>
      );
    }
  };
  return (
    <motion.div
      className="motion-div"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.25 } }}
    >
      <section id="Contact--page">
        <motion.div
          className="form--container"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
        >
          <div className="top-bar--decoration" />
          {activeForm === false ? (
            <>
              <div className="unavailable-disclaimer">
                <h3>
                  Currently unavailable. Please check back later. Thank you.
                </h3>
              </div>
            </>
          ) : (
            <>
              <div className="steps--container">
                <div className="progress-bar" />
                <div
                  className={`${step === 1 ? "active-step" : "step step-1"}`}
                >
                  <span>1</span>
                </div>
                <div
                  className={`${step === 2 ? "active-step" : "step step-2"}`}
                >
                  <span>2</span>
                </div>
                <div className="step step-3">
                  <span>3</span>
                </div>
              </div>

              {showValidationError()}
              {step === 1 && (
                <div className="input--wrapper">
                  <Input
                    type="text"
                    label="Firstname (required)"
                    placeholder="Firstname"
                    value={formData.firstname}
                    onChange={handleInputChange("firstname")}
                  />

                  <Input
                    type="text"
                    label="Lastname (required)"
                    placeholder="Lastname"
                    value={formData.lastname}
                    onChange={handleInputChange("lastname")}
                  />

                  <Input
                    type="text"
                    label="Alias (optional)"
                    placeholder="Alias"
                    value={formData.alias ?? ""}
                    onChange={handleInputChange("alias")}
                  />

                  <Input
                    type="text"
                    label="Age (required)"
                    placeholder="Age"
                    value={formData.age.toString()}
                    onChange={handleInputChange("age")}
                  />

                  <Input
                    type="text"
                    label="Country (optional)"
                    placeholder="Country"
                    value={formData.country ?? ""}
                    onChange={handleInputChange("country")}
                  />

                  <div className="button--wrapper">
                    <button
                      type="button"
                      onClick={() => handleNextClick(2)}
                      disabled={!isValidStep1()}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}

              {step === 2 && (
                <div className="input--wrapper">
                  <Input
                    type="text"
                    label="Email (required)"
                    placeholder="Email"
                    value=""
                    onChange={() => {}}
                  />

                  <div className="button--wrapper">
                    <button type="button" onClick={() => setStep(1)}>
                      Back
                    </button>
                    <button
                      type="button"
                      onClick={() => setStep(3)}
                      disabled={!isValidStep2()}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}

              {step === 3 && (
                <div className="input--wrapper">
                  <Input
                    type="text"
                    label="Message title (required)"
                    placeholder="Message title"
                    value=""
                    onChange={() => {}}
                  />

                  <Textarea label="Message (required)" placeholder="Message" />

                  <div className="button--wrapper">
                    <button type="button" onClick={() => setStep(2)}>
                      Back
                    </button>
                    <button type="button" onClick={() => setStep(4)}>
                      Next
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="bottom-bar--decoration" />
        </motion.div>
      </section>
    </motion.div>
  );
};

export default Contact;

{
  /* <div className="contact-form--container">
  <h2>Send us a message</h2>
  <form>
    <Input
      type="text"
      label="Firstname (required)"
      placeholder="Firstname"
      value=""
      onChange={() => {}}
    />
    <Input
      type="text"
      label="Lastname (required)"
      placeholder="Lastname"
      value=""
      onChange={() => {}}
    />
    <Input
      type="text"
      label="Age (required)"
      placeholder="Age"
      value=""
      onChange={() => {}}
    />
    <Input
      type="text"
      label="Email (required)"
      placeholder="Email"
      value=""
      onChange={() => {}}
    />
    <Input
      type="text"
      label="Alias (optional)"
      placeholder="Alias"
      value=""
      onChange={() => {}}
    />
    <Input
      type="text"
      label="Country (optional)"
      placeholder="Country"
      value=""
      onChange={() => {}}
    />
    <Input
      type="text"
      label="Message title (required)"
      placeholder="Message title"
      value=""
      onChange={() => {}}
    />
    <Textarea label="Message (required)" placeholder="Message" />
    <button> Send message </button>
  </form>
</div> */
}
