import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { images } from "../constants/images";
import { useInView } from "react-intersection-observer";
import Accordion from "../components/Accordion";
import Button from "../components/Button";

const About: React.FC = () => {
  const [allowAnimations, setAllowAnimations] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAllowAnimations(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  //=========================================
  // Setup useInView for each section
  const animationVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const [animation1Ref, animation1InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation2Ref, animation2InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation3Ref, animation3InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation4Ref, animation4InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation5Ref, animation5InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation6Ref, animation6InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });

  const [animation7Ref, animation7InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  return (
    <motion.div
      className="motion-div"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.25 } }}
    >
      <section id="About--page">
        <section className="WELCOME--section">
          <div className="content--wrapper">
            <div className="top-bar" />

            <div className="content--container">
              <motion.div
                ref={animation1Ref}
                initial="hidden"
                animate={animation1InView ? "visible" : "hidden"}
                variants={animationVariants}
                // animate={welcomeInView ? "visible" : "hidden"}
              >
                <div className="text">
                  <div className="titles--container">
                    <h2>Unveiling Our Vision</h2>
                    <h3>
                      The essence of the <br /> Awakened Generation Network{" "}
                    </h3>
                  </div>

                  <p>
                    We are an intersection of visionaries, innovation, and
                    transformative aspirations. Our story is woven from the
                    threads of collective ambition for growth, a commitment to
                    redefine success, and a relentless pursuit of truth.
                  </p>
                </div>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section>

        <section className="ESSENCE">
          <div className="content--wrapper">
            <motion.div
              className="content--container"
              ref={animation2Ref}
              initial="hidden"
              animate={animation2InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="content-text">
                <div className="titles--container">
                  <h2> The Essence </h2>
                  <h3> </h3>
                </div>

                <p>
                  At its core, the network is a convergence of truthseekers,
                  visionaries and innovators, united by a shared commitment to
                  personal growth and collective progress. It embodies
                  inclusivity, diversity, and a culture of mutual support.
                </p>

                <p>
                  Our network stands as a beacon of unity, calling forth those
                  who dance to the rhythm of awakened consciousness. It's a
                  refuge where the enlightened gather, no longer drifting in
                  solitude amidst the silence but converging to create an
                  orchestra of enlightened souls.
                </p>
              </div>

              <figure className="content-image">
                <img src={images.essence} alt="" />
              </figure>
            </motion.div>
          </div>
        </section>

        <section className="ORIGIN">
          <div className="content--wrapper">
            <motion.div
              className="content--container"
              ref={animation3Ref}
              initial="hidden"
              animate={animation3InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <figure className="image">
                <img src={images.origin} alt="" />
              </figure>

              <div className="text">
                <div className="titles--container">
                  <h2> The Origin </h2>
                  <h3> </h3>
                </div>

                <p>
                  The founder, a seeker of truth and a champion of
                  enlightenment, recognized the silent struggles of those who
                  walked the path of awakening. They felt the pulse of countless
                  luminous souls striving for connection, seeking refuge in a
                  world where isolation often overshadowed unity.
                </p>

                <p>
                  Motivated by a fervent need to bridge this divide, the founder
                  set out to create a sanctuary — a virtual hearth where
                  enlightened minds could converge, share insights, and find
                  strength in collective awakening. This vision, birthed from a
                  deep understanding of the importance of community in these
                  transformative times, ignited the creation of the Awakened
                  Generation Network.
                </p>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="MISSION">
          <div className="content--wrapper">
            <div className="top-bar" />

            <div className="content--container">
              <motion.div
                ref={animation4Ref}
                initial="hidden"
                animate={animation4InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <h2>The Mission</h2>

                <div className="text">
                  <p>
                    Envisioning an escape from the solitude often tied to
                    awakening, the founder imagined a digital oasis destined to
                    blossom into a physical sanctuary — a place for the awakened
                    to gather, inspire, and uplift. It's a nurturing environment
                    for the united spirits' harmonious symphony, fostering
                    profound interconnectedness beyond barriers.
                  </p>
                  <p>
                    <b>
                      At the core of our network resides a singular mission:
                      unveiling the profound truths shaping our reality and
                      disseminating them worldwide.
                    </b>
                  </p>
                  <p>
                    Together, we empower, inspire, and unite around core values
                    guiding our collective. We create spaces for personal
                    evolution, collaboration, and enlightenment, steadfast in
                    championing diversity, inclusivity, and collective growth.
                  </p>
                </div>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section>

        <section className="FUTURE">
          <div className="content--wrapper">
            <motion.div
              className="content--container"
              ref={animation7Ref}
              initial="hidden"
              animate={animation7InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="content-text">
                <div className="titles--container">
                  <h2> The Future </h2>
                  <h3> </h3>
                </div>

                <p>
                  Looking ahead, The Awakened Generation Network aspires to
                  evolve into not just a digital hub but a physical sanctuary
                  where awakened minds can connect, inspire, and uplift each
                  other.
                </p>

                <p>
                  We envision <b>expanding our reach</b>,{" "}
                  <b>diversifying our content</b>, {""}
                  {""}
                  <b>
                    and continuously adapting to the ever-changing landscape of
                    knowledge and technology
                  </b>
                  , {""}
                  <b>diversifying our content</b>,{" "}
                  <b>
                    {" "}
                    and continuously adapting to the ever-changing landscape of
                    knowledge and technology
                  </b>
                  .
                </p>
              </div>

              <figure className="content-image">
                <img src={images.network_003} alt="" />
              </figure>
            </motion.div>
          </div>
        </section>

        <section className="FAQ">
          <motion.div
            className="content--container"
            ref={animation5Ref}
            initial="hidden"
            animate={animation5InView ? "visible" : "hidden"}
            variants={animationVariants}
          >
            <h2>Frequently Asked Questions</h2>
            <Accordion />
          </motion.div>
        </section>

        <section className="JOIN--section">
          <div className="content--wrapper">
            <div className="top-bar" />

            <div className="content--container">
              <motion.div
                ref={animation6Ref}
                initial="hidden"
                animate={animation6InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="text">
                  <h2>Join Our Movement</h2>

                  <p>
                    Step into a community pulsating with diverse perspectives
                    and shared visions. Our network thrives on the energy of
                    individuals driven by curiosity, innovation, and a passion
                    for transformative change.
                  </p>

                  <p>
                    Discover how our network fosters collaboration, knowledge
                    sharing, and a culture of continuous learning.
                  </p>
                  <Button
                    text="Join the Network"
                    to="/network"
                    style={{
                      marginTop: "2rem",
                      // maxWidth: "200px",
                    }}
                  />
                </div>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section>
      </section>
    </motion.div>
  );
};

export default About;
