import React, { useState, useEffect } from "react";
import { images } from "../constants/images";
import { motion } from "framer-motion";
import { useAppSelector } from "../redux/store";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const Network: React.FC = () => {
  const networks = useAppSelector((state) => state.data.networks);

  let currentNetworks = networks.slice(0, 1);
  let upcomingNetworks = networks.slice(1, 6);

  const [allowAnimations, setAllowAnimations] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAllowAnimations(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  //=========================================
  // Setup useInView for each section
  const animationVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const [animation1Ref, animation1InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation2Ref, animation2InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation3Ref, animation3InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });

  const [animation4Ref, animation4InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });

  return (
    <motion.div
      className="motion-div"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.25 } }}
    >
      <section id="Network--page">
        <section className="WELCOME--section">
          <div className="content--wrapper">
            <div className="top-bar" />

            <div className="content--container">
              <motion.div
                className="content"
                ref={animation1Ref}
                initial="hidden"
                animate={animation1InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="text">
                  <div className="titles--container">
                    <h2>A Network Beyond Boundaries</h2>
                    <h3>Unveiling the Ecosystem</h3>
                  </div>
                  <p>
                    We are more than a network; we are a collective force — a
                    mosaic of interconnected branches, each representing a
                    unique facet of growth, empowerment, and transformation.
                  </p>

                  <p>
                    Explore our diverse branches —{" "}
                    <b>
                      {" "}
                      The Discord, The Conclave, The Mastermind, The Consortium,
                      The Facilities, and the Sanctum{" "}
                    </b>
                    — each offering a distinct realm dedicated to fostering
                    collaboration, harnessing collective wisdom, and nurturing
                    individual potential.
                  </p>

                  <p>
                    <b>
                      Each branch of The Awakened Generation Network is uniquely
                      designed to cater to various aspects of personal and
                      professional development
                    </b>
                    , providing a comprehensive platform for growth and
                    empowerment. Members can choose to engage with one or
                    multiple branches, depending on their interests and goals.
                  </p>
                </div>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section>

        <section className="NETWORKS--section">
          <motion.div
            ref={animation3Ref}
            initial="hidden"
            animate={animation3InView ? "visible" : "hidden"}
            variants={animationVariants}
          >
            <div className="CURRENT">
              <h2>Connect with us</h2>
              <div className="content--container">
                {currentNetworks.map((network, i) => (
                  <div
                    key={i}
                    className="network--card"
                    style={{
                      backgroundImage: `url(${network.banner})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="card--overlay">
                      <div className="card-title">
                        {/* <h3 style={{ color: `#${network.color}` }}>{network.type} </h3> */}
                        <h3>{network.type} </h3>
                      </div>
                      {/* 
              <div className="card-price">
                <h4>{network.price} </h4>
              </div> */}

                      <div className="card-description">
                        <p> {network.description} </p>
                      </div>

                      {/* <ul className="menu">
                {network.perks.map((perk, i) => (
                  <li>{perk}</li>
                ))}
              </ul> */}

                      <div className="card--button">
                        <Link className="link" to={`/${network.link}`}>
                          More Information
                        </Link>
                        {/* <Button
                  buttonText="More Information"
                  style={{
                    background: "var(--darkgrey-200)",
                    width: "280px",
                    height: "30px",
                  }}
                /> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </motion.div>

          <div className="UPCOMING">
            <motion.div
              ref={animation4Ref}
              initial="hidden"
              animate={animation4InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <h2>UPCOMING</h2>

              <div className="content--container">
                {upcomingNetworks.map((network, i) => (
                  <div
                    key={i}
                    className="network--card"
                    style={{
                      backgroundImage: `url(${network.banner})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="card--overlay">
                      <div className="card-title">
                        {/* <h3 style={{ color: `#${network.color}` }}>{network.type} </h3> */}
                        <h3>{network.type} </h3>
                      </div>
                      {/* 
              <div className="card-price">
                <h4>{network.price} </h4>
              </div> */}

                      <div className="card-description">
                        <p> {network.description} </p>
                      </div>

                      {/* <ul className="menu">
                {network.perks.map((perk, i) => (
                  <li>{perk}</li>
                ))}
              </ul> */}

                      <div className="card--button">
                        <Link className="link" to={`/${network.link}`}>
                          More Information
                        </Link>
                        {/* <Button
                  buttonText="More Information"
                  style={{
                    background: "var(--darkgrey-200)",
                    width: "280px",
                    height: "30px",
                  }}
                /> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          </div>
        </section>
      </section>
    </motion.div>
  );
};

export default Network;
