import React, { useState, useEffect } from "react";
// import "./Notification.css"; // You might need to adjust this import based on your file structure

const Notification: React.FC = () => {
  const [showNotification, setShowNotification] = useState<boolean>(false);

  useEffect(() => {
    // Set a timeout to show the notification after 1 second (adjust as needed)
    const notificationTimeout = setTimeout(() => {
      setShowNotification(true);
    }, 1000);

    // Clear the timeout on component unmount
    return () => {
      clearTimeout(notificationTimeout);
    };
  }, []);

  const closeNotification = () => {
    setShowNotification(false);
  };

  return (
    <div
      className={`notification ${showNotification ? "showNotification" : ""}`}
    >
      <h3
        style={{
          color: "var(--color--main)",
        }}
      >
        Welcome!
      </h3>
      <p>We're currently under construction. </p>
      <button onClick={closeNotification}>Close</button>
    </div>
  );
};

export default Notification;
