import React from "react";

const TOSPolicy: React.FC = () => {
  return (
    <div id="TOS-Policy--page">
      <h1>Terms of Service</h1>
      {/* Add your Terms of Service content here */}
    </div>
  );
};

export default TOSPolicy;
