import React, { useState, useEffect } from "react";
import { images } from "../constants/images";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Button from "../components/Button";

// sections
import { WelcomeSection } from "../components/Sections";
import { FeaturesSection } from "../components/Sections";

const Consortium: React.FC = () => {
  //=========================================
  // animations setup variables
  const [allowAnimations, setAllowAnimations] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAllowAnimations(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  //=========================================
  // Setup useInView for each section
  const animationVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const [animation1Ref, animation1InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation2Ref, animation2InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation3Ref, animation3InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation4Ref, animation4InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation5Ref, animation5InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });

  return (
    <motion.div
      className="motion-div"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.25 } }}
    >
      <section id="Consortium--page">
        <section className="COMING-SOON">
          <div className="content--container">
            <h3>COMING SOON</h3>
            <p>
              The Consortium is currently under construction. Please check back
              soon for updates.
            </p>
          </div>
        </section>

        <WelcomeSection
          ref={animation1Ref}
          initial="hidden"
          animate={animation1InView ? "visible" : "hidden"}
          variants={animationVariants}
          title="Welcome to The Consortium"
          subTitle="Where Collaboration Shapes Futures"
          textParagraph="The Consortium stands as the epitome of synergy,
          collaboration, and collective empowerment within the
          Awakened Generation Network — an alliance where
          partnerships forge new paths and redefine possibilities."
          textParagraph2="  Step into a collaborative realm where innovation meets
          collaboration. The Consortium is a strategic alliance
          nurturing dynamic partnerships and groundbreaking
          initiatives."
        />

        <FeaturesSection
          ref={animation5Ref}
          initial="hidden"
          animate={animation5InView ? "visible" : "hidden"}
          variants={animationVariants}
          features={["Networking", "Collaboration", "Expertise", "Innovation"]}
        />

        <section className="INTRO--section">
          <div className="content--wrapper">
            <motion.div
              ref={animation2Ref}
              initial="hidden"
              animate={animation2InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="content--container">
                <figure className="image">
                  <img src={images.consortiumIMG_2} />
                </figure>

                <div className="text">
                  <h2>Empowering Collaborative Ventures</h2>
                  <p>
                    At its core, The Consortium is about forging connections
                    that transcend conventional boundaries. It's a gathering of
                    minds where expertise is shared, partnerships are formed,
                    and groundbreaking ideas take shape.
                  </p>

                  <p>
                    It serves as a collaborative platform where knowledge,
                    experience, and innovation intersect, fostering a culture of
                    mutual growth and collective success.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="PERKS--section">
          <div className="content--wrapper">
            <motion.div
              ref={animation3Ref}
              initial="hidden"
              animate={animation3InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="content--container">
                <div className="text">
                  <h2>Four Key Features of The Consortium</h2>

                  <div className="perk">
                    <h3>Strategic Partnerships</h3>
                    <p>
                      Access a network of industry leaders and innovators,
                      fostering collaborations that drive meaningful change and
                      mutual growth.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Innovation Ecosystem</h3>

                    <p>
                      Engage in a vibrant ecosystem where disruptive ideas are
                      nurtured, tested, and brought to fruition through
                      collective efforts.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Resource Amplification</h3>
                    <p>
                      Leverage shared resources, expertise, and networks to
                      accelerate ventures and initiatives toward scalable
                      success.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Collaborative Opportunities</h3>
                    <p>
                      Engage in joint ventures, projects, and partnerships that
                      leverage collective strengths.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Collaborative Opportunities</h3>
                    <p>
                      Engage in joint ventures, projects, and partnerships that
                      leverage collective strengths.
                    </p>
                  </div>
                </div>

                <figure className="image">
                  <img src={images.consortiumIMG_1} />
                </figure>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="JOIN--section">
          <div className="content--wrapper">
            <div className="top-bar" />
            <div className="content--container">
              <motion.div
                ref={animation4Ref}
                initial="hidden"
                animate={animation4InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="text">
                  <h2>Let's Shape Tomorrow, Together</h2>

                  <p>
                    In The Consortium, collaboration isn’t just a strategy; it’s
                    a movement. Are you ready to join a league where shared
                    visions shape destinies and collective efforts redefine
                    industries?
                  </p>

                  <div className="buttons--container">
                    <Button
                      text="Join the Discord"
                      to="https://discord.gg/stuZTEga"
                      style={{
                        marginTop: "2rem",
                        // maxWidth: "200px",
                      }}
                    />
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section>
      </section>
    </motion.div>
  );
};

export default Consortium;

{
  /* <section className="WELCOME--section">
          <div className="content--wrapper">
            <div className="top-bar" />

            <div className="content--container">
              <motion.div
                ref={animation1Ref}
                initial="hidden"
                animate={animation1InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="text">
                  <div className="titles--container">
                    <h2>Welcome to The Consortium</h2>
                    <h3>Where Collaboration Shapes Futures</h3>
                  </div>

                  <div className="text--container">
                    <p>
                      The Consortium stands as the epitome of synergy,
                      collaboration, and collective empowerment within the
                      Awakened Generation Network — an alliance where
                      partnerships forge new paths and redefine possibilities.
                    </p>

                    <p>
                      Step into a collaborative realm where innovation meets
                      collaboration. The Consortium is a strategic alliance
                      nurturing dynamic partnerships and groundbreaking
                      initiatives.
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section>

        <section className="FEATURES--section">
          <div className="content--wrapper">
            <div className="content--container">
              <motion.div
                ref={animation5Ref}
                initial="hidden"
                animate={animation5InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="feature--list">
                  <div className="feature">
                    <h3>Networking</h3>
                  </div>

                  <div className="feature">
                    <h3>Collaboration</h3>
                  </div>

                  <div className="feature">
                    <h3>Expertise</h3>
                  </div>

                  <div className="feature">
                    <h3>Innovation</h3>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </section> */
}
