import React, { useState, useEffect } from "react";
import { images } from "../constants/images";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import Button from "../components/Button";

import { WelcomeSection } from "../components/Sections";
import { FeaturesSection } from "../components/Sections";

interface Props {}

const Discord: React.FC<Props> = () => {
  //=========================================
  // animations setup variables
  const [allowAnimations, setAllowAnimations] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAllowAnimations(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  //=========================================
  // Setup useInView for each section
  const animationVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const [animation1Ref, animation1InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation2Ref, animation2InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation3Ref, animation3InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation4Ref, animation4InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation5Ref, animation5InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });

  return (
    <motion.div
      className="motion-div"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.25 } }}
    >
      <section id="Discord--page">
        <WelcomeSection
          ref={animation1Ref}
          initial="hidden"
          animate={animation1InView ? "visible" : "hidden"}
          variants={animationVariants}
          title="Welcome to The Discord"
          subTitle="Where like-minded individuals meet"
          textParagraph="At the root of the Awakened Generation Network lies The Discord — a vibrant, inclusive server open to all."
        />
        {/* <section className="WELCOME--section">
          <div className="content--wrapper">
            <div className="top-bar" />

            <div className="content--container">
              <motion.div
                ref={animation1Ref}
                initial="hidden"
                animate={animation1InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="text">
                  <div className="titles--container">
                    <h2>Welcome to The Discord</h2>
                    <h3>Where like-minded individuals meet </h3>
                  </div>

                  <p>
                    At the root of the Awakened Generation Network lies The
                    Discord — a vibrant, inclusive server open to all.
                  </p>
                </div>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section> */}

        <FeaturesSection
          ref={animation5Ref}
          initial="hidden"
          animate={animation5InView ? "visible" : "hidden"}
          variants={animationVariants}
          features={["Discussions", "Support", "Community", "Resources"]}
        />

        {/* <section className="FEATURES--section">
          <div className="content--wrapper">
            <div className="content--container">
              <motion.div
                ref={animation5Ref}
                initial="hidden"
                animate={animation5InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="feature--list">
                  <div className="feature">
                    <h3>Discussions</h3>
                  </div>

                  <div className="feature">
                    <h3>Support</h3>
                  </div>

                  <div className="feature">
                    <h3>Community</h3>
                  </div>

                  <div className="feature">
                    <h3>Resources</h3>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </section> */}

        <section className="INTRO--section">
          <div className="content--wrapper">
            <motion.div
              ref={animation2Ref}
              initial="hidden"
              animate={animation2InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="content--container">
                <figure className="image">
                  <img src={images.discordIMG_0} />
                </figure>

                <div className="text">
                  <h2>Join the Gathering</h2>

                  <p>
                    Step into a world where ideas flow freely and innovation
                    thrives. Engage in discussions, exchange valuable insights,
                    and connect with a diverse spectrum of individuals
                    passionate about growth and progress.
                  </p>

                  <p>
                    <b>
                      The Discord stands as a cornerstone of The Awakened
                      Generation Network, embodying a vibrant, virtual sanctuary
                      dedicated to spiritual exploration and personal
                      enlightenment. Here, members from around the globe gather
                      to delve into the profound realms of spirituality, seeking
                      to understand deeper truths and embrace their journey
                      towards self-discovery.
                    </b>
                  </p>

                  <p>
                    Through consistent engagement and valuable contributions,
                    members have the chance to earn an exclusive invitation to
                    the next tier within our network, The Mastermind.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="PERKS--section">
          <div className="content--wrapper">
            <motion.div
              ref={animation3Ref}
              initial="hidden"
              animate={animation3InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="content--container">
                <div className="text">
                  <h2>Key Features</h2>

                  <div className="perk">
                    <h3>Interactive Discussion Channels</h3>
                    <p>
                      Dedicated channels covering a wide range of spiritual
                      topics, from meditation and mindfulness to metaphysics and
                      ancient wisdom.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Resource Library</h3>

                    <p>
                      A curated collection of resources including articles,
                      e-books, and videos on spirituality and personal growth.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Member-Led Initiatives</h3>
                    <p>
                      Opportunities for members to lead discussions, share their
                      experiences, or host sessions on topics they are
                      passionate about.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Path to Advancement</h3>
                    <p>
                      Showcasing dedication and value can pave the way for
                      progression within the Awakened Generation Network.
                    </p>
                  </div>
                </div>

                <figure className="image">
                  <img src={images.discordIMG_1} />
                </figure>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="JOIN--section">
          <div className="content--wrapper">
            <div className="top-bar" />

            <div className="content--container">
              <motion.div
                ref={animation4Ref}
                initial="hidden"
                animate={animation4InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="text">
                  <h2>Join Us Today</h2>

                  <p>
                    Embrace the power of collective wisdom. Whether you're a
                    seasoned professional or an eager learner, our Discord
                    invites you to be part of a thriving ecosystem, where
                    together, we propel each other toward excellence.
                  </p>

                  <Button
                    text="Join the Discord"
                    to="https://discord.gg/stuZTEga"
                    style={{
                      marginTop: "2rem",
                      // maxWidth: "200px",
                    }}
                  />
                </div>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section>
      </section>
    </motion.div>
  );
};

export default Discord;
