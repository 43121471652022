import React from "react";
import { useNavigate } from "react-router";

interface ButtonProps {
  onClick?: () => void;
  text: string;
  to?: string;
  // children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  text,
  to,
  style,
  className,
  disabled,
}) => {
  const navigate = useNavigate();

  const isExternalLink = (url: string) => {
    const externalUrlPattern = /^https?:\/\//;
    return externalUrlPattern.test(url);
  };

  const handleClick = () => {
    if (to) {
      if (isExternalLink(to)) {
        // Open in a new tab for external URLs
        window.open(to, "_blank");
      } else {
        // Use navigate for internal routing
        navigate(to);
      }
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <div id="Button--component" style={style}>
      <button
        onClick={handleClick}
        className={` ${className || ""}`}
        disabled={disabled}
      >
        {text}
      </button>
    </div>
  );
};

export default Button;
