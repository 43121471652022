import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router";
import { images } from "./constants/images";
import { motion } from "framer-motion";

// page
import Home from "./pages/Home";
import Discord from "./pages/Discord";
import Conclave from "./pages/Conclave";
import Mastermind from "./pages/Mastermind";
import Consortium from "./pages/Consortium";
import Facilities from "./pages/Facilities";
import Sanctum from "./pages/Sanctum";

import Network from "./pages/Network";
import About from "./pages/About";
import Mission from "./pages/Mission";
import Contact from "./pages/Contact";

import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import TOSPolicy from "./pages/TOSPolicy";

// layout
import Background from "./layout/Background";
import AnimatedBackground from "./layout/AnimatedBackground";

// components
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import Notification from "./components/Notification";

// utils
import ScrollToTop from "./utils/ScrollToTop";
import Cover from "./pages/Cover";

function App() {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState<string>("/");

  const [showWebsite, setShowWebsite] = useState<boolean>(false);

  //=========================================
  // hero variables
  let pageTitle: string = "";
  let subTitle: string = "";
  let backgroundImage: string = "";
  let backgroundHeight: string = "";
  let button: boolean = false;

  interface PageDataType {
    [key: string]: {
      title: string;
      subTitle: string;
      backgroundImage: string;
      backgroundHeight: string;
      button?: boolean;
    };
  }

  //=========================================
  // hero settings
  const pageData: PageDataType = {
    "/": {
      title: "Awakened Generation Network",
      subTitle:
        "An exclusive worldwide community that shares insights, knowledge and guidance on comprehending the intricacies of reality and leveraging that understanding to your benefit",
      backgroundImage: images.heroBG_3,
      backgroundHeight: "1000px",
      button: true,
    },
    "/discord": {
      title: "Discord",
      subTitle: "",
      backgroundImage: images.discord_header,
      backgroundHeight: "400px",
      button: true,
    },
    "/conclave": {
      title: "Conclave",
      subTitle: "",
      backgroundImage: images.conclave_header,
      backgroundHeight: "400px",
      button: true,
    },
    "/mastermind": {
      title: "Mastermind",
      subTitle: "",
      backgroundImage: images.mastermind_header,
      backgroundHeight: "400px",
      button: true,
    },
    "/consortium": {
      title: "Consortium",
      subTitle: "",
      backgroundImage: images.consortium_header,
      backgroundHeight: "400px",
      button: true,
    },
    "/facilities": {
      title: "Facilities",
      subTitle: "",
      backgroundImage: images.facilitiesIMG_0,
      backgroundHeight: "400px",
      button: true,
    },
    "/sanctum": {
      title: "Sanctum",
      subTitle: "",
      backgroundImage: images.sanctumIMG_0,
      backgroundHeight: "400px",
      button: true,
    },
    "/about": {
      title: "About Us",
      subTitle: "",
      backgroundImage: images.network_header_002,
      backgroundHeight: "400px",
      button: true,
    },
    "/network": {
      title: "The Network",
      subTitle:
        "A place for the Awakened to share their knowledge and insights with each other.",
      backgroundImage: images.network_header_001,
      backgroundHeight: "400px",
      button: true,
    },

    "/contact": {
      title: "",
      subTitle: "",
      backgroundImage: "",
      backgroundHeight: "",
      button: false,
    },
  };

  const currentPageData = pageData[location.pathname];

  //=========================================
  // footer conditional rendering
  const showFooter: boolean = ![
    "/register",
    "authentication",
    "/login",
  ].includes(location.pathname);

  //=========================================
  // hero conditional rendering
  const renderHero = (): JSX.Element | null => {
    const excludeHome = location.pathname === "/home";
    const excludePaths: string[] = ["authentication", "/register", "/login"];
    const isExcludedPath: boolean = excludePaths.some((path) =>
      location.pathname.includes(path)
    );

    if (!showWebsite)
      return (
        <Cover showWebsite={showWebsite} setShowWebsite={setShowWebsite} />
      );
    if (isExcludedPath || location.pathname === "/home") return null;

    // if (isExcludedPath) return null;

    return (
      <Hero
        showWebsite={showWebsite.toString()} // Convert boolean to string
        setShowWebsite={setShowWebsite}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageTitle={pageTitle}
        subTitle={subTitle}
        backgroundImage={backgroundImage}
        backgroundHeight={backgroundHeight}
        button={true}
      />
    );
  };

  if (currentPageData) {
    pageTitle = currentPageData.title;
    subTitle = currentPageData.subTitle;
    backgroundImage = currentPageData.backgroundImage;
    backgroundHeight = currentPageData.backgroundHeight;
  }

  return (
    <motion.div
      className="motion-div"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.25 } }}
    >
      <ScrollToTop />
      {renderHero()}
      {showWebsite ? (
        <>
          <Routes>
            <Route
              path="/"
              element={<Home setCurrentPage={setCurrentPage} />}
            />
            <Route path="/discord" element={<Discord />} />
            <Route path="/conclave" element={<Conclave />} />
            <Route path="/mastermind" element={<Mastermind />} />
            <Route path="/consortium" element={<Consortium />} />
            <Route path="/facilities" element={<Facilities />} />
            <Route path="/sanctum" element={<Sanctum />} />
            <Route path="/network" element={<Network />} />

            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/terms-of-service" element={<TOSPolicy />} />
          </Routes>

          <Background />
          <AnimatedBackground />
          <Notification />

          {showFooter && <Footer />}
        </>
      ) : (
        <Cover showWebsite={showWebsite} setShowWebsite={setShowWebsite} />
      )}
    </motion.div>
  );
}

export default App;
