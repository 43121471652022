import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { images } from "../constants/images";

// Import Button and other components
import Button from "../components/Button";

// Define types for social media and topics if needed

const Home: React.FC<{ setCurrentPage: (page: string) => void }> = ({
  setCurrentPage,
}) => {
  let topics = [
    {
      id: 0,
      title: "Esoterica",
      tooltip: "",
      description: "",
      url: "",
    },
    {
      id: 0,
      title: "Occult",
      tooltip: "",
      description: "",
      url: "",
    },
    {
      id: 0,
      title: "Cybersecurity",
      tooltip: "",
      description: "",
      url: "",
    },
    {
      id: 0,
      title: "Technology",
      tooltip: "",
      description: "",
      url: "",
    },
    {
      id: 0,
      title: "Society",
      tooltip: "",
      description: "",
      url: "",
    },
    {
      id: 0,
      title: "Blockchain",
      tooltip: "",
      description: "",
      url: "",
    },
    {
      id: 0,
      title: "Science",
      tooltip: "",
      description: "",
      url: "",
    },
    {
      id: 0,
      title: "Self-improvement",
      tooltip: "",
      description: "",
      url: "",
    },
    {
      id: 0,
      title: "Artificial Intelligence",
      tooltip: "",
      description: "",
      url: "",
    },
    {
      id: 0,
      title: "Manifestation",
      tooltip: "",
      description: "",
      url: "",
    },
    {
      id: 0,
      title: "Finance",
      tooltip: "",
      description: "",
      url: "",
    },
    {
      id: 0,
      title: "Entrepreneurship",
      tooltip: "",
      description: "",
      url: "",
    },
  ];

  let coreValues = [
    {
      id: 0,
      title: "Enlightenment",
      description:
        "Promoting a deeper understanding of the self and the world.",
    },
    {
      id: 0,
      title: "Empowerment",
      description:
        "Equipping members with the knowledge and resources to realize their potential.",
    },
    {
      id: 0,
      title: "Collaboration",
      description:
        "Embracing the power of collective wisdom and shared experiences.",
    },
    {
      id: 0,
      title: "Innovation",
      description:
        "Encouraging creative thinking and embracing cutting-edge technologies.",
    },
    {
      id: 0,
      title: "Integrity",
      description:
        "Maintaining a space of respect, authenticity, and ethical conduct.",
    },
  ];

  const [allowAnimations, setAllowAnimations] = useState<boolean>(false);

  useEffect(() => {
    // Delay setting allowAnimations to true
    const timer = setTimeout(() => {
      setAllowAnimations(true);
    }, 500); // Delay of 500ms, adjust as needed

    return () => clearTimeout(timer);
  }, []);

  //=========================================
  // Setup useInView for each section
  const animationVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const animationRedPill = {
    hidden: { x: -300 },
    visible: { x: 0, transition: { duration: 2.5 } },
  };

  const animationBluePill = {
    hidden: { x: 300 },
    visible: { x: 0, transition: { duration: 2.5 } },
  };

  const animationDecorationLine = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 4.5 } },
  };

  const [animation1Ref, animation1InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation2Ref, animation2InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation3Ref, animation3InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation4Ref, animation4InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation5Ref, animation5InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation6Ref, animation6InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation7Ref, animation7InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation8Ref, animation8InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });

  return (
    <motion.div
      className="motion-div"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.25 } }}
    >
      <main id="Home--page">
        <section className="WELCOME--section">
          <div className="content--wrapper">
            <div className="top-bar" />
            <div className="content--container">
              <motion.div
                className="text"
                ref={animation1Ref}
                initial="hidden"
                animate={animation1InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <h2>Welcome</h2>

                <p>
                  You have arrived here with a purpose, guided by an enigmatic
                  force propelling you towards a transcendent state of
                  consciousness. In your pursuit of heightened awareness,
                  profound questions have consumed your thoughts.
                </p>

                <p>
                  <b>Who am I truly? </b>
                  <b>What is the core of my being?</b>
                  {""}{" "}
                  <b>
                    What purpose does my existence serve in this intricate
                    world?
                  </b>
                  {""} <b>Why have I been shaped in this unique manner?</b>
                  {""}{" "}
                  <b>
                    Is the world as it appears, or is there a concealed hand
                    guiding the orchestration of reality?
                  </b>
                  <b></b>
                  <b></b>
                </p>

                <p>
                  These questions have ignited the fires of your quest for
                  understanding and have brought you here.
                </p>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section>

        <section className="MATRIX--section">
          <div className="content--wrapper">
            <div className="content--container">
              <motion.div
                className="text"
                ref={animation8Ref}
                initial="hidden"
                animate={animation8InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="titles--container">
                  <h2>Choose Your Reality</h2>
                  <h3>The Ultimatum</h3>
                </div>
                <div className="ultimatum--container">
                  <div className="line--decoration">
                    <motion.div
                      ref={animation8Ref}
                      initial="hidden"
                      animate={animation8InView ? "visible" : "hidden"}
                      variants={animationDecorationLine}
                    >
                      <div className="decoration--container">
                        <div className="freedom">Freedom</div>

                        <div className="line" />

                        <div className="enslavement">Enslavement</div>
                      </div>
                    </motion.div>
                  </div>

                  <div className="choice--container">
                    <motion.div
                      ref={animation8Ref}
                      initial="hidden"
                      animate={animation8InView ? "visible" : "hidden"}
                      variants={animationRedPill}
                    >
                      <figure className="image">
                        <img src={images.red_pill} />
                      </figure>

                      <div className="text">
                        <div className="titles--container">
                          <h3
                            style={{
                              color: `var(--color--red-pill)`,
                            }}
                          >
                            The Red Pill
                          </h3>
                          <h4>Breaking The Illusion</h4>
                        </div>

                        <p>
                          The red pill symbolizes the willingness to learn a
                          potentially unsettling or life-changing truth. By
                          choosing the red pill, you're choosing to embrace a
                          journey of self-discovery and truth.
                        </p>
                      </div>
                    </motion.div>
                  </div>

                  <div className="choice--container">
                    <motion.div
                      ref={animation8Ref}
                      initial="hidden"
                      animate={animation8InView ? "visible" : "hidden"}
                      variants={animationBluePill}
                    >
                      <figure className="image">
                        <img src={images.blue_pill} />
                      </figure>

                      <div className="text">
                        <div className="titles--container">
                          <h3
                            style={{
                              color: `var(--color--blue-pill)`,
                            }}
                          >
                            The Blue Pill
                          </h3>
                          <h4>Embracing The Illusion</h4>
                        </div>

                        <p>
                          The blue pill, on the other hand, represents the
                          choice to stay within the comfort of known reality,
                          without challenging or questioning the status quo.
                          It's a decision to remain within the familiar confines
                          of everyday life.
                        </p>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <section className="INTRODUCTION--section">
          <div className="content--wrapper">
            <div className="content--container">
              <motion.div
                ref={animation2Ref}
                initial="hidden"
                animate={animation2InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="titles--container">
                  <h2>Uncover The Truth</h2>
                </div>

                <div className="video-container">
                  <iframe
                    style={{
                      borderRadius: `var(--main-radius)`,
                    }}
                    width="100%"
                    height="515"
                    src="https://www.youtube.com/embed/SblWDVn58NI"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>

                <div className="text">
                  <p>
                    Since your arrival in this world,{" "}
                    <b>
                      you've been indoctrinated in an intricate system
                      meticulously designed to confine your mind within an
                      imperceptible prison — an insidious mental blockade that
                      hides the true nature of reality
                    </b>
                    . Your very biology has been orchestrated against you,
                    creating unyielding patterns that seem nearly impossible to
                    break free from.
                    {""}{" "}
                    <b>
                      Your emotions, cunningly manipulated, confine you to a
                      state of diminished awareness and your devine energy being
                      drained out of you.
                    </b>
                  </p>

                  <p>
                    <b>
                      You've been taught what to think rather than how to think
                    </b>
                    , ensnared by a distorted version of history that belittles
                    your significance.{" "}
                    <b>
                      The truth about your identity and boundless potential has
                      been obscured from your grasp, leaving your innate powers
                      shrouded in mystery.
                    </b>{" "}
                    This elaborate scheme, known to us as 'The Matrix,' has
                    entrapped generations across the Earth, fostering blind
                    faith in authoritarian figures who spread deceit disguised
                    as indisputable scientific truths.
                  </p>

                  <p>
                    <b>
                      Nevertheless, here you are, led by your intuition — a
                      force guiding you purposefully. {""}
                    </b>
                    The world has brought you here for a reason. Your presence
                    in this moment signifies a profound event — the emergence of
                    The Great Awakening.{" "}
                    <b>
                      Across this expansive realm, humanity is awakening to the
                      realization of nefarious forces striving to manipulate and
                      subdue us.
                    </b>
                  </p>

                  <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <b>
                      The moment has arrived for you to enlighten your mind to
                      the truth that has been kept away from you, revealing the
                      boundless potential that resides within us all.
                    </b>
                  </p>

                  {/* <p>
                  Since the time you have been born, you've been introctinated
                  in an advanced system created to keep you mind in an invisble
                  box -- unable to see the world for what it truly is. Your
                  biological system's have been turned against you to keep you
                  in concrete routines, almost impossible to break free from.
                  Your emotions have been manipulated to keep you in a lower
                  state of conscioussness. You have learned what to think and
                  not how to think. You have been deceived into believing a
                  false history that makes you seem ignisificant. You have been
                  kept away from the truth about who and what you truly are.
                  Resuling in you not knowing what powers you posses We call
                  this system; The Matrix. Many different generations accross
                  the Earth have put their blind trust in authorian figures who
                  spread lies disguised as scientific facts.
                </p> */}
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <section className="INTRO--section">
          <div className="content--wrapper">
            <motion.div
              ref={animation6Ref}
              initial="hidden"
              animate={animation6InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="content--container">
                <div className="text">
                  <h2>What is the Awakened Generation Network?</h2>

                  <p>
                    The Awakened Generation Network represents a visionary
                    initiative, uniquely crafted to serve as a comprehensive
                    ecosystem for individuals seeking to explore and leverage
                    the intricacies of reality.
                  </p>

                  <p>
                    It stands as a testament to the power of collective wisdom,
                    personal growth, and transformative knowledge across diverse
                    fields, ranging from spirituality and science to
                    entrepreneurship and technology.
                  </p>
                </div>

                <figure className="image">
                  <img src={images.network_001} />
                </figure>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="VALUES--section">
          <div className="content--wrapper">
            <motion.div
              ref={animation7Ref}
              initial="hidden"
              animate={animation7InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="content--container">
                <h2>Our Core Values</h2>

                <div className="value--list">
                  {coreValues.map((value, i) => (
                    <div className="value--block">
                      <div className="block-top--decoration" />
                      <div className="value">
                        <h3> {value.title} </h3>
                      </div>
                      {/* <p>{value.description}</p> */}
                      <div className="block-bottom--decoration" />
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="NETWORK--section">
          <div className="content--wrapper">
            <motion.div
              className="content--container"
              ref={animation3Ref}
              initial="hidden"
              animate={animation3InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <figure className="image">
                <img src={images.network_004} />
              </figure>

              <div className="text">
                <h2>The Network</h2>
                <p>
                  <b>
                    Our network fosters a space for open dialogue, sharing
                    diverse perspectives, and fostering personal growth.
                  </b>
                  {""} We curate thought-provoking content, engage in meaningful
                  discussions, and connect with like-minded individuals who seek
                  to expand their consciousness.
                </p>

                {/* <p>
                We're not just a community; we are a force—an assembly of
                passionate minds dedicated to sculpting the future of our
                businesses, careers, and personal aspirations.
              </p> */}

                <p>
                  In these spaces, embark on a journey of self-exploration and
                  empowerment. It's where passion meets purpose, and individuals
                  dedicate themselves to uncovering and maximizing their latent
                  potential.
                </p>

                <Button
                  text="Explore the Network"
                  to="/network"
                  onClick={() => setCurrentPage("/network")}
                  style={{
                    marginTop: "2rem",
                    // maxWidth: "200px",
                  }}
                />
              </div>
            </motion.div>
          </div>
        </section>

        <section className="TOPICS--section">
          <div id="topics--wrapper">
            <div className="top-bar" />

            <div className="topics--container">
              <motion.div
                ref={animation4Ref}
                initial="hidden"
                animate={animation4InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="text">
                  <h2>Our Hot Topics</h2>

                  <p>
                    We love in-depth discussions tailored to enlighten and
                    empower your exploration of specific subjects. Uncover a
                    wealth of information designed to elevate your understanding
                    and expertise in your chosen areas of interest
                  </p>
                </div>

                <ul className="topics--menu">
                  {topics.map((topic, i) => (
                    <li className="menu--item">
                      <div className="block-top--decoration" />
                      <h4>{topic.title}</h4>
                      <div className="block-bottom--decoration" />
                    </li>
                  ))}
                </ul>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section>

        <section className="MISSION--section">
          <div className="content--wrapper">
            <motion.div
              className="content--container"
              ref={animation5Ref}
              initial="hidden"
              animate={animation5InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="text">
                <h2>THE MISSION</h2>

                <p>
                  Our vision is to establish a worldwide network that transcends
                  traditional boundaries, fostering an environment where
                  knowledge, insights, and experiences are shared freely and
                  with purpose.
                </p>

                <p>
                  <b>
                    Our mission is to empower individuals by providing access to
                    a mosaic of interconnected branches, each designed to
                    nurture different aspects of personal and professional
                    growth.
                  </b>
                </p>

                <p>
                  We aim to cultivate a space where open dialogue, diverse
                  perspectives, and profound interconnectedness are not just
                  encouraged but celebrated.
                </p>
                <Button
                  text="Uncover the Mission"
                  to="/about"
                  onClick={() => setCurrentPage("/about")}
                  style={{
                    marginTop: "2rem",
                    // maxWidth: "200px",
                  }}
                />
              </div>

              {/* <div
                className="image--container"
                style={{
                  backgroundImage: `url(${images.mission})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
              </div> */}
              <figure className="image">
                <img src={images.mission} />
              </figure>
            </motion.div>
          </div>
        </section>
      </main>
    </motion.div>
  );
};

export default Home;
