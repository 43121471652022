import React, { useState } from "react";

interface Props {
  // Define the props for your component here
}

const HamburgerMenu: React.FC<Props> = (props) => {
  const [click, setClick] = useState<boolean>(false);

  // Implement your component logic here

  return (
    <div id="HamburgerMenu--component">
      <label htmlFor="navi-toggle">
        <span className=""></span>
      </label>
    </div>
  );
};

export default HamburgerMenu;
