import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { images } from "../constants/images";
import { useInView } from "react-intersection-observer";
import Button from "../components/Button";

// sections
import { WelcomeSection } from "../components/Sections";
import { FeaturesSection } from "../components/Sections";

const Facilities: React.FC = () => {
  const facilityPerks = [
    {
      id: "01",
      title: "Clean and Sanitized Spaces",
      short_description:
        "Regular cleaning schedules and sanitation protocols ensure that all spaces within the facilities maintain high standards of cleanliness, promoting a hygienic environment for members.",
    },
    {
      id: "02",
      title: "Tranquil Surroundings",
      short_description:
        "Design elements, such as natural lighting, green spaces, and soothing color schemes, create a serene ambiance that fosters relaxation and concentration.",
    },
    {
      id: "03",
      title: "Well-Maintained Environments",
      short_description:
        "Carefully tended landscapes, including gardens, walking paths, or outdoor seating areas, offer tranquil spaces for contemplation and relaxation amidst nature.",
    },
    {
      id: "04",
      title: "Wellness Amenities",
      short_description:
        "Access to amenities like showers, massage chairs, or quiet rooms for brief meditation sessions supports members in maintaining their overall well-being during their time in the facilities.",
    },
  ];

  const facilities = [
    {
      id: "01",
      name: "Belgium",
      // preview_image: require("../assets/images/facilities/facilities_001.png"),
      preview_description: "",
    },
    {
      id: "02",
      name: "Chili",
      // preview_image: require("../assets/images/facilities/facilities_002.png"),
      preview_description: "",
    },
    {
      id: "03",
      name: "Mauritius",
      // preview_image: require("../assets/images/facilities/facilities_003.png"),
      preview_description: "",
    },
    {
      id: "04",
      name: "Peru",
      // preview_image: require("../assets/images/facilities/facilities_004.png"),
      preview_description: "",
    },
  ];

  //=========================================
  // animations setup variables
  const [allowAnimations, setAllowAnimations] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAllowAnimations(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  //=========================================
  // Setup useInView for each section
  const animationVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const [animation1Ref, animation1InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation2Ref, animation2InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation3Ref, animation3InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation4Ref, animation4InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation5Ref, animation5InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation6Ref, animation6InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation7Ref, animation7InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });

  return (
    <motion.div
      className="motion-div"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.25 } }}
    >
      <section id="Facilities--page">
        <section className="COMING-SOON">
          <div className="content--container">
            <h3>COMING SOON</h3>
            <p>
              Presently, there are no available facilities; however, the project
              is currently underway.
            </p>
          </div>
        </section>

        <WelcomeSection
          ref={animation1Ref}
          initial="hidden"
          animate={animation1InView ? "visible" : "hidden"}
          variants={animationVariants}
          title="Welcome to The Facilities"
          subTitle="Your Path to Personal Evolution"
          textParagraph="  Step into the nurturing grounds of The Facilities — a
          specialized haven within the Awakened Generation Network
          dedicated to sculpting individuals into their most
          empowered selves."
          textParagraph2=" Immerse yourself in environments designed to ignite
          introspection, cultivate new habits, and connect with
          fellow awakened minds, enabling a journey towards
          self-discovery and profound transformation."
        />

        <FeaturesSection
          ref={animation7Ref}
          initial="hidden"
          animate={animation7InView ? "visible" : "hidden"}
          variants={animationVariants}
          features={["Learning", "Development", "Rewiring", "Empowerment"]}
        />

        <section className="INTRO--section">
          <div className="content--wrapper">
            <motion.div
              ref={animation2Ref}
              initial="hidden"
              animate={animation2InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="content--container">
                <figure className="image">
                  <img src={images.facilitiesIMG_1} />
                </figure>

                <div className="text">
                  <h2>Unlock Your Potential</h2>

                  <p>
                    Here, we don't just offer resources; we cultivate
                    transformation. Our comprehensive training and guidance
                    serve as a catalyst for rewiring mindsets, nurturing
                    strengths, and fostering holistic growth.
                  </p>

                  <p>
                    In these spaces, embark on a journey of self-exploration and
                    empowerment. It's where passion meets purpose, and
                    individuals dedicate themselves to uncovering and maximizing
                    their latent potential.
                  </p>

                  <p>
                    Discover tailor-made programs and resources crafted to
                    ignite personal evolution. Engage in workshops, coaching
                    sessions, and immersive experiences designed to propel you
                    toward your highest aspirations.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="PERKS--section">
          <div className="content--wrapper">
            <motion.div
              ref={animation3Ref}
              initial="hidden"
              animate={animation3InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="content--container">
                <div className="text">
                  <h2>Why Enter Our Facilities?</h2>

                  <div className="perk">
                    <h3>Focused Personal Development</h3>
                    <p>
                      Access a wealth of tools and mentorship to navigate your
                      personal growth journey effectively.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Mindset Rewiring</h3>

                    <p>
                      Embrace a supportive environment where mindsets are
                      challenged and growth journey effectively.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Empowerment through Knowledge</h3>
                    <p>
                      Dive into a world of learning, leveraging insights and
                      strategies to cultivate lasting self-improvement.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Innovative Learning Spaces</h3>
                    <p>
                      Offer state-of-the-art learning and meeting spaces
                      equipped with the latest technology.
                    </p>
                  </div>
                </div>

                <figure className="image">
                  <img src={images.facilitiesIMG_3} />
                </figure>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="FACILITIES">
          <div className="content--wrapper">
            <motion.div
              ref={animation4Ref}
              initial="hidden"
              animate={animation4InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <h2>Our Facilities</h2>

              <div className="facilities-perks--container">
                {facilityPerks.map((perk, i) => (
                  <div className="facilities-perk">
                    <div className="block-top--decoration" />

                    <h3> {perk.title} </h3>
                    {/* <p> {perk.short_description} </p> */}
                    <div className="block-bottom--decoration" />
                  </div>
                ))}
              </div>
            </motion.div>

            <motion.div
              ref={animation5Ref}
              initial="hidden"
              animate={animation5InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="facilities--container">
                {facilities.map((facility, i) => (
                  <figure className="image">
                    <div className="image--overlay" />
                    <div className="image-titles">
                      <h3> {facility.id} </h3>
                      <h4> {facility.name} </h4>
                    </div>
                    <img src={images.AGN_FACILITIES} />
                  </figure>
                ))}
              </div>
            </motion.div>
          </div>
        </section>

        <section className="JOIN--section">
          <div className="content--wrapper">
            <div className="top-bar" />

            <div className="content--container">
              <motion.div
                ref={animation6Ref}
                initial="hidden"
                animate={animation6InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="text">
                  <h2>Embrace Your Evolution</h2>

                  <p>
                    The Facilities beckon those yearning for profound
                    self-discovery — a haven where self-actualization isn’t just
                    a goal but a tangible destination. Join us and unleash the
                    potential within to craft the life you envision.
                  </p>

                  {/* <p>
                Transformation isn’t just an option; it's the very essence of
                our existence. Are you ready to immerse yourself in a realm
                where personal growth isn’t just encouraged but celebrated?
              </p> */}

                  <div className="buttons--container">
                    <Button
                      text="Join the Discord"
                      to="https://discord.gg/stuZTEga"
                      style={{
                        marginTop: "2rem",
                        // maxWidth: "200px",
                      }}
                    />
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section>
      </section>
    </motion.div>
  );
};

export default Facilities;

{
  /* <section className="WELCOME--section">
          <div className="content--wrapper">
            <div className="top-bar" />

            <div className="content--container">
              <motion.div
                ref={animation1Ref}
                initial="hidden"
                animate={animation1InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="text">
                  <div className="titles--container">
                    <h2>Welcome to The Facilities</h2>
                    <h3>Your Path to Personal Evolution</h3>
                  </div>

                  <div className="text--container">
                    <p>
                      Step into the nurturing grounds of The Facilities — a
                      specialized haven within the Awakened Generation Network
                      dedicated to sculpting individuals into their most
                      empowered selves.
                    </p>

                    <p>
                      Immerse yourself in environments designed to ignite
                      introspection, cultivate new habits, and connect with
                      fellow awakened minds, enabling a journey towards
                      self-discovery and profound transformation.
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section>

        <section className="FEATURES--section">
          <div className="content--wrapper">
            <div className="content--container">
              <motion.div
                ref={animation7Ref}
                initial="hidden"
                animate={animation7InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="feature--list">
                  <div className="feature">
                    <h3>Learning</h3>
                  </div>

                  <div className="feature">
                    <h3>Development</h3>
                  </div>

                  <div className="feature">
                    <h3>Rewiring</h3>
                  </div>

                  <div className="feature">
                    <h3>Empowerment</h3>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </section> */
}
