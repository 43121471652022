import React from "react";

const CookiePolicy: React.FC = () => {
  return (
    <div id="Cookie-Policy--page">
      <h1>Cookie Policy</h1>
      <p>This is the cookie policy page.</p>
      {/* Add your cookie policy content here */}
    </div>
  );
};

export default CookiePolicy;
