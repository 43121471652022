import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import Navigation from "./Navigation";
import { images } from "../constants/images";
import Button from "./Button";

interface HeroProps {
  pageTitle: string;
  subTitle: string;
  backgroundHeight: string;
  backgroundImage: string;
  currentPage: string;
  button?: boolean;
  setCurrentPage: Dispatch<SetStateAction<string>>;
  showWebsite: string;
  setShowWebsite: Dispatch<SetStateAction<boolean>>;
}

const Hero: React.FC<HeroProps> = ({
  pageTitle,
  subTitle,
  backgroundHeight,
  backgroundImage,
  currentPage,
  setCurrentPage,
  button,

  showWebsite,
  setShowWebsite,
}) => {
  const backgroundStyle: React.CSSProperties = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    height: backgroundHeight,
    // You can add more background styles here if needed
  };

  //=========================================
  // title animation (INACTIVE)
  const [showTitle, setShowTitle] = useState(false);

  const showButton = currentPage !== "/contact" && button;

  useEffect(() => {
    const showTitleContainer = () => {
      setShowTitle(true);
    };

    const timeout = setTimeout(showTitleContainer, 500);

    return () => clearTimeout(timeout);
  }, []);

  //=========================================
  // scroll to next section
  // Custom smooth scroll function
  const scrollToWelcomeSection = () => {
    const section = document.querySelector(".WELCOME--section") as HTMLElement;
    if (section) {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;
      const scrollToPosition =
        sectionTop + sectionHeight / 2.3 - window.innerHeight / 2;

      smoothScrollTo(scrollToPosition, 1500);
    }
  };

  // Function to perform smooth scroll
  // elementY: position to scroll to, duration: time in milliseconds
  const smoothScrollTo = (elementY: number, duration: number) => {
    const startingY = window.pageYOffset;
    const diff = elementY - startingY;
    let start: number;

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp: number) {
      if (!start) start = timestamp;
      // Elapsed milliseconds since start of scrolling.
      const time = timestamp - start;
      // Get percent of completion in range [0, 1].
      const percent = Math.min(time / duration, 1);

      // Apply the easing.
      // It can be adjusted to change the "feel" of the scroll.
      const ease = (percent: number) => {
        return percent < 0.5
          ? 4 * percent * percent * percent
          : (percent - 1) * (2 * percent - 2) * (2 * percent - 2) + 1;
      };

      window.scrollTo(0, startingY + diff * ease(percent));

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step);
      }
    });
  };

  return (
    <>
      <div style={{ height: "110px" }} />
      <Navigation
        // pageTitle={pageTitle}
        showWebsite={showWebsite}
        setShowWebsite={setShowWebsite}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      <header id="Hero--component" style={backgroundStyle}>
        <div className="hero--overlay" />

        <div className={`title--container ${showTitle ? "show" : ""}`}>
          {/* <h2 className={showTitle ? "show" : ""}>THE</h2> */}
          <h1 className={showTitle ? "show" : ""}>{pageTitle}</h1>
          <p className={showTitle ? "show" : "text"}>{subTitle}</p>

          {showButton && (
            <div className="button--container">
              <Button
                text="Discover More"
                onClick={scrollToWelcomeSection}
                style={{
                  maxWidth: "200px",
                }}
              />
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default Hero;
