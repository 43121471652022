import React, { useState, useEffect } from "react";
import { images } from "../constants/images";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import Button from "../components/Button";

// sections
import { WelcomeSection } from "../components/Sections";
import { FeaturesSection } from "../components/Sections";

const Mastermind: React.FC = () => {
  //=========================================
  // animations setup variables
  const [allowAnimations, setAllowAnimations] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAllowAnimations(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  //=========================================
  // Setup useInView for each section
  const animationVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const [animation1Ref, animation1InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation2Ref, animation2InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation3Ref, animation3InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation4Ref, animation4InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation5Ref, animation5InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation6Ref, animation6InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });

  return (
    <motion.div
      className="motion-div"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.25 } }}
    >
      <section id="Mastermind--page">
        <section className="COMING-SOON">
          <div className="content--container">
            <h3>COMING SOON</h3>
            <p>
              The Mastermind is currently under construction. Please check back
              soon for updates.
            </p>
          </div>
        </section>

        <WelcomeSection
          ref={animation1Ref}
          initial="hidden"
          animate={animation1InView ? "visible" : "hidden"}
          variants={animationVariants}
          title="Welcome to The Mastermind"
          subTitle="Where Visionaries Unite"
          textParagraph="    Enter the inner mind of the Awakened Generation Network — a
          dynamic collective inspired by the timeless wisdom of
          Napoleon Hill's concept of mastermind groups, redefined for
          the modern trailblazer."
        />

        <FeaturesSection
          ref={animation6Ref}
          initial="hidden"
          animate={animation6InView ? "visible" : "hidden"}
          variants={animationVariants}
          features={["Networking", "Collaboration", "Support", "Opportunities"]}
        />

        <section className="INTRO--section">
          <div className="content--wrapper">
            <motion.div
              ref={animation2Ref}
              initial="hidden"
              animate={animation2InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="content--container">
                <figure className="image">
                  <img src={images.mastermindIMG_1} />
                </figure>

                <div className="text">
                  <h2>A Nexus of Growth and Achievement</h2>

                  <p>
                    The Mastermind is more than a mere gathering — it's an
                    alliance of like-minded individuals deeply committed to
                    unlocking their fullest potential in business, career, and
                    personal development.
                  </p>

                  <p>
                    Here, small groups converge to ignite a special kind of
                    synergy. Imagine a space where passionate minds collide,
                    sharing dreams, setting ambitious yet achievable goals, and
                    embarking on a transformative journey together.
                  </p>

                  <p>
                    Whether online or in-person, members dive into brainstorming
                    sessions, hold each other accountable, and provide
                    unwavering support and guidance.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="PERKS--section">
          <div className="content--wrapper">
            <motion.div
              ref={animation3Ref}
              initial="hidden"
              animate={animation3InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="content--container">
                <div className="text">
                  <h2>Four Pillars of The Mastermind</h2>

                  <div className="perk">
                    <h3>Elite Collaboration</h3>
                    <p>
                      Experience the synergy of collaborative brainstorming and
                      problem-solving.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Accountability and Support</h3>

                    <p>
                      Join a select group where accountability is a catalyst for
                      achievement, pushing each member to surpass their limits.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Transformational Connections</h3>
                    <p>
                      Forge connections with driven individuals, leveraging
                      collective wisdom to navigate challenges and achieve
                      unparalleled triumphs.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Exclusive Insights and Opportunities</h3>
                    <p>
                      Discover and explore exclusive opportunities for growth
                      and advancement.
                    </p>
                  </div>
                </div>

                <figure className="image">
                  <img src={images.facilitiesIMG_2} />
                </figure>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="JOIN--section">
          <div className="content--wrapper">
            <div className="top-bar" />
            <div className="content--container">
              <motion.div
                ref={animation4Ref}
                initial="hidden"
                animate={animation4InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="text">
                  <h2>Join the Visionaries</h2>

                  <p>
                    The Mastermind beckons those hungry for substantial growth —
                    a haven where ambition meets action and dreams transform
                    into tangible victories. Dare to be part of a select
                    alliance that champions growth and collective triumph.
                  </p>

                  <div className="buttons--container">
                    <Button
                      text="Join the Discord"
                      to="https://discord.gg/stuZTEga"
                      style={{
                        marginTop: "2rem",
                        // maxWidth: "200px",
                      }}
                    />
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section>
      </section>
    </motion.div>
  );
};

export default Mastermind;

{
  /* <section className="WELCOME--section">
          <div className="content--wrapper">
            <div className="top-bar" />

            <div className="content--container">
              <motion.div
                ref={animation1Ref}
                initial="hidden"
                animate={animation1InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="text">
                  <div className="titles--container">
                    <h2>Welcome to The Mastermind</h2>
                    <h3>Where Visionaries Unite </h3>
                  </div>
                  <p>
                    Enter the inner mind of the Awakened Generation Network — a
                    dynamic collective inspired by the timeless wisdom of
                    Napoleon Hill's concept of mastermind groups, redefined for
                    the modern trailblazer.
                  </p>
                </div>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section> */
}

{
  /* 
        <section className="FEATURES--section">
          <div className="content--wrapper">
            <div className="content--container">
              <motion.div
                ref={animation5Ref}
                initial="hidden"
                animate={animation5InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="feature--list">
                  <div className="feature">
                    <h3>Networking</h3>
                  </div>

                  <div className="feature">
                    <h3>Collaboration</h3>
                  </div>

                  <div className="feature">
                    <h3>Support</h3>
                  </div>

                  <div className="feature">
                    <h3>Opportunities</h3>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </section> */
}
