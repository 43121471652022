import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { NavLink } from "react-router-dom";
import HamburgerMenu from "./HamburgerMenu";
import { images } from "../constants/images";
import { IoIosFingerPrint } from "react-icons/io";

interface NavigationProps {
  currentPage: string;
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>;

  showWebsite: string;
  setShowWebsite: Dispatch<SetStateAction<boolean>>;
}

const Navigation: React.FC<NavigationProps> = ({
  currentPage,
  setCurrentPage,

  showWebsite,
  setShowWebsite,
}) => {
  let AGN_logo = images.AGN_LOGO_V1;

  //   let socials = useSelector((state) => state.data.social_media);

  let socials = [
    {
      id: "3",
      name: "Discord",
      short_description: "",
      url: "https://discord.gg/stuZTEga",
    },
    {
      id: "0",
      name: "X",
      //   logo: require("../assets/images/social_media/X-LOGO.png"),
      short_description: "",
      url: "https://twitter.com/AWAKENED_GEN",
    },
    {
      id: "1",
      name: "Instagram",
      //   logo: require("../assets/images/social_media/Instagram-LOGO.png"),
      short_description: "",
      url: "https://www.instagram.com/awakenedgenerationnetwork/",
    },
    {
      id: "2",
      name: "Youtube",
      //   logo: require("../assets/images/social_media/YouTube-LOGO.png"),
      short_description: "",
      url: "https://www.youtube.com/channel/UCLQhONMQqZPyfoPEDXkHmow",
    },
    {
      id: "3",
      name: "TikTok",
      //   logo: require("../assets/images/social_media/TikTok-LOGO.png"),
      short_description: "",
      url: "https://www.tiktok.com/@awakened.generati?_t=8hnseCC3PRA&_r=1",
    },
    // {
    //   id: "3",
    //   name: "Discord",
    //   short_description: "",
    //   url: "https://discord.gg/K9cjadbBQz",
    // },
    {
      id: "4",
      name: "Pinterest",
      //   logo: require("../assets/images/social_media/Discord-LOGO.png"),
      short_description: "",
      url: "https://www.pinterest.com/awakenedgenerationnetwork/",
    },
  ];

  //=========================================
  // state variables
  const [showDropdown, setShowDropdown] = useState(false);
  const [showProductsDropdown, setShowProductsDropdown] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [showSocials, setShowSocials] = useState(false);
  const [showToolip, setShowTooltip] = useState<string | boolean>(false);

  const handleTooltip = (status: string | boolean) => {
    setShowTooltip(status);
  };

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleActivateCover = () => {
    setShowWebsite(true);
    setCurrentPage("/");
  };

  //=========================================
  // Social media menu function
  const handleShowSocials = () => {
    setShowSocials(true);
  };

  const handleHideSocials = () => {
    setShowSocials(false);
  };

  useEffect(() => {
    if (showSocials) {
      timerRef.current = setTimeout(() => {
        setShowSocials(false);
      }, 5000);
    } else {
      clearTimeout(timerRef.current!);
    }

    return () => {
      clearTimeout(timerRef.current!);
    };
  }, [showSocials]);

  const handleDivHover = () => {
    clearTimeout(timerRef.current!);
  };
  //=========================================
  // hamburger menu function
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 1050;

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //=========================================
  // Network Dropdown function
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLinkClick = () => {
    setShowDropdown(false);
  };
  //=========================================
  // Products Dropdown function
  const toggleProductsDropdown = () => {
    setShowProductsDropdown(!showProductsDropdown);
  };

  const handleProductsLinkClick = () => {
    setShowProductsDropdown(false);
  };

  return (
    <div id="Navigation--component">
      <div className="top-bar"></div>
      <nav className="nav-bar">
        <div className="nav-menu--wrapper">
          <div className="nav-menu">
            <ul className={isMobile ? "hide-menu menu" : "left-side-links"}>
              <li
                className={currentPage === "/" ? "active-link" : ""}
                onClick={() => setCurrentPage("/")}
              >
                <NavLink to="/">Home</NavLink>
              </li>
              <li
                className={currentPage === "about" ? "active-link" : ""}
                onClick={() => setCurrentPage("about")}
              >
                <NavLink to="about">About</NavLink>
              </li>

              <li
                className={currentPage === "network" ? "active-link" : ""}
                onClick={() => setCurrentPage("network")}
                onMouseEnter={toggleDropdown}
                onMouseLeave={toggleDropdown}
              >
                <NavLink to="network">Network</NavLink>

                {showDropdown && (
                  <ul className="dropdown--menu">
                    <li
                      className={currentPage === "discord" ? "active-link" : ""}
                      onClick={() => setCurrentPage("discord")}
                    >
                      <NavLink to="discord" onClick={handleLinkClick}>
                        Discord
                      </NavLink>
                    </li>
                    <li
                      className={
                        currentPage === "conclave" ? "active-link" : ""
                      }
                      onClick={() => setCurrentPage("conclave")}
                    >
                      <NavLink to="conclave" onClick={handleLinkClick}>
                        Conclave
                      </NavLink>
                    </li>
                    <li
                      className={
                        currentPage === "mastermind" ? "active-link" : ""
                      }
                      onClick={() => setCurrentPage("mastermind")}
                    >
                      <NavLink to="mastermind" onClick={handleLinkClick}>
                        Mastermind
                      </NavLink>
                    </li>

                    <li
                      className={
                        currentPage === "consortium" ? "active-link" : ""
                      }
                      onClick={() => setCurrentPage("consortium")}
                    >
                      <NavLink to="consortium" onClick={handleLinkClick}>
                        Consortium
                      </NavLink>
                    </li>
                    <li
                      className={
                        currentPage === "facilities" ? "active-link" : ""
                      }
                      onClick={() => setCurrentPage("facilities")}
                    >
                      <NavLink to="facilities" onClick={handleLinkClick}>
                        Facilities
                      </NavLink>
                    </li>
                    <li
                      className={currentPage === "sanctum" ? "active-link" : ""}
                      onClick={() => setCurrentPage("sanctum")}
                    >
                      <NavLink to="sanctum" onClick={handleLinkClick}>
                        Sanctum
                      </NavLink>
                    </li>
                  </ul>
                )}
              </li>
            </ul>

            <div
              className="AGN_logo--container"
              onMouseEnter={handleShowSocials}

              // style={{
              //   width: `${isScrolled ? "50px" : "120"}`,
              //   height: `${isScrolled ? "50px" : "100"}`,
              // }}
            >
              <figure
                className="AGN_logo"
                onClick={isMobile ? handleShowSocials : handleActivateCover}
              >
                <NavLink to="/">
                  <img src={images.AGN_official_logo_transparant} />
                </NavLink>
              </figure>

              {showSocials && (
                <div
                  className={` ${
                    showSocials
                      ? "shown---social-media--menu social-media--menu"
                      : "hiden---social-media--menu social-media--menu "
                  }`}
                  onMouseEnter={!isMobile ? handleShowSocials : undefined}
                  onMouseLeave={!isMobile ? handleHideSocials : undefined}
                >
                  <div className="socials--container">
                    {socials.map((social, i) => (
                      <a
                        key={i}
                        href={social.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {social.name}
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {isMobile && <HamburgerMenu />}

            <ul className={isMobile ? "hide-menu menu" : "right-side-links"}>
              <li
                className={currentPage === "shop" ? "active-link" : ""}
                onClick={() => setCurrentPage("shop")}
                // onMouseEnter={toggleProductsDropdown}
                // onMouseLeave={toggleProductsDropdown}
              >
                Shop
              </li>

              <li
                className={currentPage === "blog" ? "active-link" : ""}
                onClick={() => setCurrentPage("blog")}
                onMouseEnter={() => handleTooltip("blog")}
                onMouseLeave={() => handleTooltip(false)}
              >
                Blog
                {showToolip === "blog" && <div className="tooltip">Blog</div>}
              </li>

              <li
                className={currentPage === "contact" ? "active-link" : ""}
                onClick={() => setCurrentPage("contact")}
              >
                <NavLink to="contact">Contact</NavLink>
              </li>
            </ul>
          </div>

          <div className="nav-menu-2">
            <ul>
              <li
                className={
                  currentPage === "authentication" ? "active-link" : ""
                }
                onClick={() => setCurrentPage("authentication")}
                onMouseEnter={() => handleTooltip("login")}
                onMouseLeave={() => handleTooltip(false)}
              >
                <IoIosFingerPrint className="myCustomIcon" />

                {showToolip === "login" && (
                  <div className="tooltip">Account</div>
                )}
              </li>

              {/* <li
                className={currentPage === "cart" ? "active-link" : ""}
                onClick={() => setCurrentPage("cart")}
              >
                <NavLink to="cart">Join</NavLink>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      <div className="bottom-bar"></div>
    </div>
  );
};

export default Navigation;
