import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useAppSelector } from "../redux/store";

interface FAQItem {
  question: string;
  answer: string;
}

const Accordion: React.FC = () => {
  const faqItems = useAppSelector((state) => state.faq.faq);
  // const faqItems: FAQItem[] = useSelector((state) => state.faq.FAQs);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div id="Accordion">
      {faqItems.map((item, i) => (
        <div className="faq-item" onClick={() => toggleFAQ(i)} key={i}>
          <div
            className={activeIndex === i ? "active-question" : "faq-question"}
          >
            <h3> {item.question} </h3>
            <span>{activeIndex === i ? <>-</> : <>+</>}</span>
          </div>

          <div className={activeIndex === i ? "show" : "content"}>
            <p>{item.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
