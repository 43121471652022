import heroBG from "../assets/images/hero_bg.jpg";
import heroBG_2 from "../assets/images/hero_bg_2.png";
import heroBG_3 from "../assets/images/header_bg_3.png";
import AGN_LOGO_V1 from "../assets/images/AGN_LOGO_V1.png";
import AGN_official_logo_transparant from "../assets/images/logo/AGN_official_logo_transparant.png";
// import AGN_COMMUNITY from "../assets/images/AGN_COMMUNITY.png";
// import AGN_CONCLAVE from "../assets/images/AGN_CONCLAVE.png";
// import AGN_MASTERMIND from "../assets/images/AGN_Mastermind.png";
// import AGN_CONSORTIUM from "../assets/images/AGN_CONSORTIUM.png";
// import AGN_FACILITIES from "../assets/images/AGN_FACILITIES.png";
// import AGN_SANCTUM from "../assets/images/AGN_SANCTUM.png";

// home page
import red_pill from "../assets/images/pill_red.png";
import blue_pill from "../assets/images/pill_blue.png";

//networks banners
import AGN_DISCORD from "../assets/images/networks/AGN_DISCORD.png";
import AGN_CONCLAVE from "../assets/images/networks/AGN_CONCLAVE.png";
import AGN_MASTERMIND from "../assets/images/networks/AGN_MASTERMIND.png";
import AGN_CONSORTIUM from "../assets/images/networks/AGN_CONSORTIUM.png";
import AGN_FACILITIES from "../assets/images/networks/AGN_FACILITIES.png";
import AGN_SANCTUM from "../assets/images/networks/AGN_SANCTUM.png";

// network
import network_004 from "../assets/images/network_004.png";

// discord
import discord_header from "../assets/images/discord_header.png";
import discordIMG_0 from "../assets/images/discord_000.png";
import discordIMG_1 from "../assets/images/discord_001.png";

// conclave
import conclave_header from "../assets/images/conclave_header.png";
import conclaveIMG_0 from "../assets/images/conclave_000.png";
import conclaveIMG_1 from "../assets/images/conclave_001.png";
import conclaveIMG_2 from "../assets/images/conclave_002.png";
import conclaveIMG_4 from "../assets/images/conclave_004.png";
import conclaveIMG_3 from "../assets/images/conclave_003.png";

// mastermind
import mastermind_header from "../assets/images/mastermind_header.png";
import mastermindIMG_0 from "../assets/images/mastermind_000.png";
import mastermindIMG_1 from "../assets/images/mastermind_001.png";
import mastermindIMG_2 from "../assets/images/mastermind_002.png";

// consortium
import consortium_header from "../assets/images/consortium_header.png";
import consortiumIMG_0 from "../assets/images/consortium_000.png";
import consortiumIMG_1 from "../assets/images/consortium_001.png";
import consortiumIMG_2 from "../assets/images/consortium_002.jpg";

// facilities
import facilitiesIMG_0 from "../assets/images/facilities_000.png";
import facilitiesIMG_1 from "../assets/images/facilities_001.png";
import facilitiesIMG_2 from "../assets/images/facilities_002.png";
import facilitiesIMG_3 from "../assets/images/facilities_003.png";

// sanctum
import sanctumIMG_0 from "../assets/images/sanctum_000.png";
import sanctumIMG_1 from "../assets/images/sanctum_001.png";

// network
import network_header from "../assets/images/network_header.png";
import network_header_000 from "../assets/images/network_header_000.png";
import network_header_001 from "../assets/images/network_header_001.png";
import network_header_002 from "../assets/images/network_header_002.png";
import network from "../assets/images/network_000.png";
import network_000 from "../assets/images/network.png";
import network_001 from "../assets/images/network_001.png";
import network_002 from "../assets/images/network_002.png";
import network_003 from "../assets/images/network_003.png";

// about
import origin from "../assets/images/origin.png";
import essence from "../assets/images/essence.png";
import BRUMAJUCA from "../assets/images/brumajucaAGN.png";
import mission from "../assets/images/mission.png";

export const images = {
  heroBG,
  heroBG_2,
  heroBG_3,

  AGN_LOGO_V1,
  AGN_official_logo_transparant,

  red_pill,
  blue_pill,

  // networks
  AGN_DISCORD,
  AGN_CONCLAVE,
  AGN_MASTERMIND,
  AGN_CONSORTIUM,
  AGN_FACILITIES,
  AGN_SANCTUM,

  discord_header,
  discordIMG_0,
  discordIMG_1,

  // network
  network_004,

  // conclave
  conclave_header,
  conclaveIMG_0,
  conclaveIMG_1,
  conclaveIMG_2,
  conclaveIMG_3,
  conclaveIMG_4,

  // mastermind
  mastermind_header,
  mastermindIMG_0,
  mastermindIMG_1,
  mastermindIMG_2,

  // consortium
  consortium_header,
  consortiumIMG_0,
  consortiumIMG_1,
  consortiumIMG_2,

  // facilities
  facilitiesIMG_0,
  facilitiesIMG_1,
  facilitiesIMG_2,
  facilitiesIMG_3,

  // sanctum
  sanctumIMG_0,
  sanctumIMG_1,

  // network
  network_header,
  network_header_000,
  network_header_001,
  network_header_002,
  network,
  network_000,
  network_001,
  network_002,
  network_003,

  origin,
  essence,
  BRUMAJUCA,
  mission,
};
