import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { images } from "../../constants/images";

export interface NetworkData {
  is_active: boolean;
  id: number;
  link: string;
  type: string;
  title: string;
  color: string;
  price: string;
  banner: string;
  description: string;
  perks: string[];
}

export interface DataState {
  networks: NetworkData[];
}

const initialState: DataState = {
  networks: [
    {
      is_active: true,
      id: 0,
      link: "discord",
      type: "Discord",
      title: "Awakaned Generation Network",
      color: "3fa9f5",
      price: "Free to join",
      banner: images.AGN_DISCORD,
      description:
        "Join a Discord hub of like-minded individuals passionate about Spirituality. Engage in lively discussions, share insights, and connect with fellow enthusiasts.",
      perks: [
        "Get to know new like-minded individuals.",
        "Communicate about knowledge & experiences.",
        "Become inspired and motivated to achieve your dreams.",
        "Access to hundreds of free book pdf's.",
        "Access to free snippets of AGN guides & books.",
      ],
    },
    {
      is_active: true,
      id: 0,
      link: "conclave",
      type: "Conclave",
      title: "Awakaned Generation Network",
      color: "3fa9f5",
      price: "€9.99 per month or a free earned spot",
      banner: images.AGN_CONCLAVE,
      description:
        "An exclusive group of dedicated like-minded individuals passionate about wealth and growth. ",
      perks: [
        "Get to know new like-minded individuals.",
        "Communicate about knowledge & experiences.",
        "Become inspired and motivated to achieve your dreams.",
        "Access to hundreds of free book pdf's.",
        "Access to free snippets of AGN guides & books.",
      ],
    },
    {
      is_active: true,
      id: 0,
      link: "mastermind",
      type: "Mastermind",
      title: "Awakaned Generation Mastermind",
      color: "ffff00",
      price: "€29.99 per month or a free earned spot ",
      banner: images.AGN_MASTERMIND,
      description:
        "Highly exclusive small group of highly motivated & determined people who come together to defy odds and win in areas you care about most.",
      perks: [
        "Meet dedicated people invested in growing their professional career.",
        "Brainstorm sessions",
        "Exclusive support and advice.",
        "Access to free e-books created by AGN.",
        "Cryptocurrency investments & guidance.",
        "Virtual life meetings.",
        "Real life meetings.",
      ],
    },
    {
      is_active: true,
      id: 0,
      link: "consortium",
      type: "Consortium",
      title: "Awakaned Generation Consortium",
      color: "f15a24",
      price: "€49.99 per month",
      banner: images.AGN_CONSORTIUM,
      description:
        "Our affiliate partnership program — an opportunity for mutually beneficial collaboration. ",
      perks: [],
    },
    {
      is_active: false,
      id: 0,
      link: "facilities",
      type: "Facilities",
      title: "Awakened Generation Facilities",
      color: "ff1d25",
      price: "€129.99 per day ",
      banner: images.AGN_FACILITIES,
      description:
        "Step into one of our facilities, where individuals dedicated to personal growth come together to unlock their full potential. ",
      perks: [],
    },
    {
      is_active: false,
      id: 0,
      link: "sanctum",
      type: "Sanctum",
      title: "Awakened Generation Sanctum",
      color: "009245",
      price: "€899.99 per week ",
      banner: images.AGN_SANCTUM,
      description:
        "Our sanctum — a sacred space devoted to inner growth, spiritual nourischment, and self-discovery.",
      perks: [],
    },
  ],
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    addNetwork: (state, action: PayloadAction<NetworkData>) => {
      state.networks.push(action.payload);
    },
    updateNetwork: (state, action: PayloadAction<NetworkData>) => {
      const { id } = action.payload;
      const index = state.networks.findIndex((network) => network.id === id);
      if (index !== -1) {
        state.networks[index] = action.payload;
      }
    },
    removeNetwork: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      state.networks = state.networks.filter((network) => network.id !== id);
    },
  },
});

export default dataSlice.reducer;
export const { addNetwork, updateNetwork, removeNetwork } = dataSlice.actions;
