import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { images } from "../constants/images";
import { useInView } from "react-intersection-observer";
import Button from "../components/Button";

// sections
import { WelcomeSection } from "../components/Sections";
import { FeaturesSection } from "../components/Sections";

const Sanctum: React.FC = () => {
  //=========================================
  // animations setup variables
  const [allowAnimations, setAllowAnimations] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAllowAnimations(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  //=========================================
  // Setup useInView for each section
  const animationVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const [animation1Ref, animation1InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation2Ref, animation2InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation3Ref, animation3InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation4Ref, animation4InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation5Ref, animation5InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });
  const [animation6Ref, animation6InView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
    skip: !allowAnimations,
  });

  return (
    <motion.div
      className="motion-div"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.25 } }}
    >
      <section id="Sanctum--page">
        <section className="COMING-SOON">
          <div className="content--container">
            <h3>COMING SOON</h3>
            <p>
              At present, there isn't a sanctum, but the project is currently in
              progress.
            </p>
          </div>
        </section>

        <WelcomeSection
          ref={animation1Ref}
          initial="hidden"
          animate={animation1InView ? "visible" : "hidden"}
          variants={animationVariants}
          title="Welcome to The Sanctum"
          subTitle="Where Wisdom Guides Transformation"
          textParagraph="The Sanctum stands as the pinnacle of introspection,
          wisdom, and profound transformation within the Awakened
          Generation Network — a sacred space reserved for deep
          exploration and personal revelation."
        />

        <FeaturesSection
          ref={animation5Ref}
          initial="hidden"
          animate={animation5InView ? "visible" : "hidden"}
          variants={animationVariants}
          features={["Meditation", "Discovery", "Reflection", "Transformation"]}
        />

        <section className="INTRO--section">
          <div className="content--wrapper">
            <motion.div
              ref={animation2Ref}
              initial="hidden"
              animate={animation2InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="content--container">
                <figure className="image">
                  <img src={images.sanctumIMG_0} />
                </figure>

                <div className="text">
                  <h2>Unveiling the Inner Sanctum</h2>

                  <p>
                    The Sanctum is our safe haven; it's a sanctuary where
                    seekers of truth and enlightenment converge to navigate the
                    inner landscape of their being.
                  </p>

                  <p>
                    It is more than just a place; it's an experience that caters
                    to the soul's deepest yearnings for peace, understanding,
                    and connection. It's where members find sanctuary to
                    reflect, meditate, and grow in a tranquil and supportive
                    environment.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="PERKS--section">
          <div className="content--wrapper">
            <motion.div
              ref={animation3Ref}
              initial="hidden"
              animate={animation3InView ? "visible" : "hidden"}
              variants={animationVariants}
            >
              <div className="content--container">
                <div className="text">
                  <h2>Why Enter The Sanctum?</h2>

                  <div className="perk">
                    <h3>Inner Exploration</h3>
                    <p>
                      Access tools and teachings that facilitate a deeper
                      understanding of self, nurturing inner peace and spiritual
                      growth.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Holistic Enlightenment</h3>

                    <p>
                      Engage in practices that harmonize mind, body, and spirit,
                      fostering a balanced and enlightened way of living.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Guidance and Mentorship</h3>
                    <p>
                      Benefit from the wisdom of mentors and guides versed in
                      various contemplative traditions, offering guidance on the
                      path to self-realization.
                    </p>
                  </div>

                  <div className="perk">
                    <h3>Reflective Community Discussions</h3>
                    <p>
                      Engage in meaningful conversations with like-minded
                      individuals on topics of spirituality and personal growth.
                    </p>
                  </div>
                </div>

                <figure className="image">
                  <img src={images.sanctumIMG_1} />
                </figure>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="JOIN--section">
          <div className="content--wrapper">
            <div className="top-bar" />

            <div className="content--container">
              <motion.div
                ref={animation4Ref}
                initial="hidden"
                animate={animation4InView ? "visible" : "hidden"}
                variants={animationVariants}
              >
                <div className="text">
                  <h2>Elevate Your Consciousness</h2>

                  <p>
                    In The Sanctum, enlightenment isn’t a destination; it's a
                    sacred journey. Are you ready to embark on a path where
                    self-discovery isn’t just sought but celebrated?
                  </p>

                  <div className="buttons--container">
                    <Button
                      text="Join the Discord"
                      to="https://discord.gg/stuZTEga"
                      style={{
                        marginTop: "2rem",
                        // maxWidth: "200px",
                      }}
                    />
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="bottom-bar" />
          </div>
        </section>
      </section>
    </motion.div>
  );
};

export default Sanctum;

{
  /* <section className="WELCOME--section">
<div className="content--wrapper">
  <div className="top-bar" />

  <div className="content--container">
    <motion.div
      ref={animation1Ref}
      initial="hidden"
      animate={animation1InView ? "visible" : "hidden"}
      variants={animationVariants}
    >
      <div className="text">
        <div className="titles--container">
          <h2>Welcome to The Sanctum</h2>
          <h3>Where Wisdom Guides Transformation</h3>
        </div>

        <div className="text--container">
          <p>
            The Sanctum stands as the pinnacle of introspection,
            wisdom, and profound transformation within the Awakened
            Generation Network — a sacred space reserved for deep
            exploration and personal revelation.
          </p>
        </div>
      </div>
    </motion.div>
  </div>
  <div className="bottom-bar" />
</div>
</section>

<section className="FEATURES--section">
<div className="content--wrapper">
  <div className="content--container">
    <motion.div
      ref={animation5Ref}
      initial="hidden"
      animate={animation5InView ? "visible" : "hidden"}
      variants={animationVariants}
    >
      <div className="feature--list">
        <div className="feature">
          <h3>Meditation</h3>
        </div>

        <div className="feature">
          <h3>Discovery</h3>
        </div>

        <div className="feature">
          <h3>Reflection</h3>
        </div>

        <div className="feature">
          <h3>Transformation</h3>
        </div>
      </div>
    </motion.div>
  </div>
</div>
</section> */
}
