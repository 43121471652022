import React, { forwardRef, useState, useEffect } from "react";
import { images } from "../constants/images";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

// =========================================
// WELCOME SECTION
interface WelcomeSectionProps {
  className?: string;
  //   ref?: React.RefObject<HTMLDivElement>;
  initial?: string;
  animate?: any;
  variants?: any;

  title?: string;
  subTitle?: string;

  textParagraph?: string;
  textParagraph2?: string;

  backgroundImage?: string;
}

// Using forwardRef to handle the ref prop
export const WelcomeSection = forwardRef<HTMLDivElement, WelcomeSectionProps>(
  (
    {
      className,
      initial,
      animate,
      variants,
      title,
      subTitle,
      textParagraph,
      textParagraph2,
    },
    ref
  ) => {
    return (
      <section className={`WELCOME--section ${className || ""}`}>
        <div className="content--wrapper">
          <div className="top-bar" />
          <div className="content--container">
            <motion.div
              ref={ref}
              initial={initial}
              animate={animate ? "visible" : "hidden"}
              variants={variants}
            >
              <div className="text">
                <div className="titles--container">
                  <h2>{title}</h2>
                  <h3>{subTitle}</h3>
                </div>
                {textParagraph && <p>{textParagraph}</p>}
                {textParagraph2 && <p>{textParagraph2}</p>}
              </div>
            </motion.div>
          </div>
          <div className="bottom-bar" />
        </div>
      </section>
    );
  }
);

// =========================================
// FEATURES SECTION
interface FeatureProps {
  className?: string;
  initial?: string;
  animate?: any;
  variants?: any;

  title?: string;
  subTitle?: string;

  textParagraph?: string;
  textParagraph2?: string;

  features?: string[];
}

export const FeaturesSection = forwardRef<HTMLDivElement, FeatureProps>(
  (
    {
      className,
      initial,
      animate,
      variants,

      title,
      subTitle,
      textParagraph,
      textParagraph2,

      features,
    },
    ref
  ) => {
    return (
      <section className={`FEATURES--section ${className || ""}`}>
        <div className="content--wrapper">
          <div className="content--container">
            <motion.div
              ref={ref}
              initial={initial}
              animate={animate ? "visible" : "hidden"}
              variants={variants}
            >
              <div className="feature--list">
                {features?.map((feature, i) => (
                  <div className="feature" key={i}>
                    <div className="block-top--decoration" />
                    <h3>{feature}</h3>
                    <div className="block-bottom--decoration" />
                  </div>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    );
  }
);
