import { configureStore } from "@reduxjs/toolkit";

import dataReducer from "./slices/dataSlice";
import faqReducer from "./slices/faqSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export const store = configureStore({
  reducer: {
    data: dataReducer,
    faq: faqReducer,
  },
});

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;

export default store;
