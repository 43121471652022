import React from "react";

interface TextareaProps {
  label: string;
  placeholder: string;
}

const Textarea: React.FC<TextareaProps> = ({ label, placeholder }) => {
  return (
    <div id="Textarea--component">
      <label>{label}</label>
      <textarea placeholder={placeholder}></textarea>
    </div>
  );
};

export default Textarea;
