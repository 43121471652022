import React from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  const siteMap = [
    {
      id: "",
      slug: "/",
      title: "Home",
      url: "",
    },
    {
      id: "",
      slug: "about",
      title: "About",
      url: "",
    },
    {
      id: "",
      slug: "network",
      title: "Network",
      url: "",
    },
    {
      id: "",
      slug: "discord",
      title: "Discord",
      url: "",
    },
    {
      id: "",
      slug: "conclave",
      title: "Conclave",
      url: "",
    },
    {
      id: "",
      slug: "mastermind",
      title: "Mastermind",
      url: "",
    },
    {
      id: "",
      slug: "consortium",
      title: "Consortium",
      url: "",
    },
    {
      id: "",
      slug: "facilities",
      title: "Facilities",
      url: "",
    },
    {
      id: "",
      slug: "sanctum",
      title: "Sanctum",
      url: "",
    },
    {
      id: "",
      slug: "contact",
      title: "Contact",
      url: "",
    },
    // {
    //   id: "",
    //   slug: "",
    //   title: "",
    //   url: "",
    // },
  ];

  const socialMedia = [
    {
      id: "0",
      name: "X",
      // logo: require("../assets/images/social_media/X-LOGO.png"),
      short_description: "",
      url: "https://twitter.com/AWAKENED_GEN",
    },
    {
      id: "1",
      name: "Instagram",
      // logo: require("../assets/images/social_media/Instagram-LOGO.png"),
      short_description: "",
      url: "https://www.instagram.com/awakenedgenerationnetwork",
    },
    {
      id: "2",
      name: "Youtube",
      // logo: require("../assets/images/social_media/YouTube-LOGO.png"),
      short_description: "",
      url: "https://www.youtube.com/channel/UCLQhONMQqZPyfoPEDXkHmow",
    },
    {
      id: "3",
      name: "TikTok",
      // logo: require("../assets/images/social_media/TikTok-LOGO.png"),
      short_description: "",
      url: "https://www.tiktok.com/@awakened.generati?_t=8hnseCC3PRA&_r=1",
    },
    // {
    //   id: "3",
    //   name: "Discord",
    //   short_description: "",
    //   url: "https://discord.gg/K9cjadbBQz",
    // },
    {
      id: "4",
      name: "Pinterest",
      // logo: require("../assets/images/social_media/Discord-LOGO.png"),
      short_description: "",
      url: "https://www.pinterest.com/awakenedgenerationnetwork/",
    },
  ];

  return (
    <footer id="Footer">
      <div className="network-information--wrapper">
        <div className="network-summary">
          <h3>Awakaned Generation Network</h3>

          <p>
            An exclusive community that shares insights and guidance on
            comprehending the intricacies of reality and leveraging that
            understanding to your benefit.
          </p>
        </div>

        <div className="sitemap--container">
          <h3>Sitemap</h3>

          <ul>
            {siteMap.map((page, i) => (
              <li key={i}>
                <Link to={page.slug}>{page.title}</Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="social-media--container">
          <h3>Follow us</h3>

          <ul>
            {socialMedia.map((social, i) => (
              <li key={i}>
                <a
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={i}
                >
                  {social.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="policies">
        <ul>
          <li>
            <Link to="privacy-policy">Privacy Policy</Link>
          </li>

          <li>
            <Link to="terms-of-service">Terms of Service</Link>
          </li>

          <li>
            <Link to="cookie-policy">Cookies</Link>
          </li>
        </ul>
      </div>

      <div className="copyright-policy--wrapper">
        <p>
          © Copyright 2023, Awakened Generation Network, All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
