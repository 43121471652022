import React, { Dispatch, SetStateAction, useState } from "react";
import { images } from "../constants/images";
import Background from "../layout/Background";

interface CoverProps {
  showWebsite: boolean;
  setShowWebsite: Dispatch<SetStateAction<boolean>>;
}

const Cover: React.FC<CoverProps> = ({ showWebsite, setShowWebsite }) => {
  const [hoverDuration, setHoverDuration] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [hideCover, setHideCover] = useState(false);

  const handleShowWebsite = () => {
    setShowWebsite(true);
    setHideCover(true); // Add this line
  };

  const startHover = () => {
    const id = setInterval(() => {
      setHoverDuration((prevDuration) => prevDuration + 1);
    }, 100);
    setIntervalId(id);
  };

  const endHover = () => {
    if (intervalId) clearInterval(intervalId);
    setHoverDuration(0);
  };

  // const handleShowWebsite = () => {
  //   setShowWebsite(true);
  // };
  let socials = [
    {
      id: "3",
      name: "Discord",
      short_description: "",
      url: "https://discord.gg/stuZTEga",
    },
    {
      id: "0",
      name: "X",
      //   logo: require("../assets/images/social_media/X-LOGO.png"),
      short_description: "",
      url: "https://twitter.com/AWAKENED_GEN",
    },
    {
      id: "1",
      name: "Instagram",
      //   logo: require("../assets/images/social_media/Instagram-LOGO.png"),
      short_description: "",
      url: "https://www.instagram.com/awakenedgenerationnetwork/",
    },
    {
      id: "2",
      name: "Youtube",
      //   logo: require("../assets/images/social_media/YouTube-LOGO.png"),
      short_description: "",
      url: "https://www.youtube.com/channel/UCLQhONMQqZPyfoPEDXkHmow",
    },
    {
      id: "3",
      name: "TikTok",
      //   logo: require("../assets/images/social_media/TikTok-LOGO.png"),
      short_description: "",
      url: "https://www.tiktok.com/@awakened.generati?_t=8hnseCC3PRA&_r=1",
    },

    {
      id: "4",
      name: "Pinterest",
      //   logo: require("../assets/images/social_media/Discord-LOGO.png"),
      short_description: "",
      url: "https://www.pinterest.com/awakenedgenerationnetwork/",
    },
  ];
  return (
    <div id="Cover--page" className={hideCover ? "hide" : ""}>
      <div className="top-bar--decoration" />
      <div className="closeButton">
        {/* <button onClick={handleShowWebsite}>
          <span>X</span>
        </button> */}
      </div>
      <div className="content--container">
        <div
          className="Logo"

          // onMouseEnter={startHover}
          // onMouseLeave={endHover}
        >
          <figure className="logo--container">
            <img
              className="logo--image"
              //   style={{
              //     animationDuration: `${100 - Math.min(hoverDuration, 15)}s`,
              //   }}
              src={images.AGN_official_logo_transparant}
              alt="logo"
            />
          </figure>
        </div>

        <div className="titles--container">
          <div className="titles">
            <h3>Welcome to the </h3>
            <h1>
              <span>Awakened Generation Network</span>
            </h1>
            <h4>Website</h4>
          </div>

          {/* <div className="text--wrapper">
            <p>
              An exclusive worldwide community that shares insights, knowledge
              and guidance on comprehending the intricacies of reality and
              leveraging that understanding to your benefit
            </p>
          </div> */}
        </div>

        <div className="socials">
          <h4>Discover the power of community.</h4>
          <ul>
            {socials.map((social, i) => (
              <li>
                <div className="top-bar--decoration" />
                <a href={social.url} target="blank">
                  {social.name}{" "}
                </a>
                <div className="bottom-bar--decoration" />
              </li>
            ))}
          </ul>
        </div>

        <div className="bottomButton">
          <button onClick={handleShowWebsite}>
            <span>Enter Website</span>
          </button>
        </div>
      </div>

      <div className="bottom-bar--decoration" />
      {/* <div className="content--wrapper"></div> */}
      <Background />
    </div>
  );
};

export default Cover;
